import axios from 'axios';

axios.default.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const instance = axios.create();
instance.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use = instance.interceptors.request.use;
/*  instance.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = `token ${localStorage.getItem('token')}`
      .replace(/(^\")|(\"$)/g, '')
  }
  return config
}, err => {
  return Promise.reject(err)
})  */
// axios拦截响应
instance.interceptors.response.use((response) => response, (err) => Promise.reject(err));

export default {
  updateAreas(data) {
    return instance.post('/api/updateareas', data);
  },
  updateLines(data) {
    return instance.post('/api/updatelines', data);
  },
  getAreas(data) {
    return instance.post('/api/getareas', data);
  },
  getWorkers(data) {
    return instance.post('/api/getworkers', data);
  },
  getDashboard(data) {
    return instance.post('/api/getdashboard', data);
  },
  workerUpdate(data) {
    return instance.post('/api/workerupdate', data);
  },
  getUnbook(data) {
    return instance.post('/api/getunbook', data);
  },
  unbookCreate(data) {
    return instance.post('/api/unbookcreate', data);
  },
  createEvent(data) {
    return instance.post('/api/createevent', data);
  },
  updateEvent(data) {
    return instance.post('/api/updateevent', data);
  },
  removeEvent(data) {
    return instance.post('/api/removeevent', data);
  },
  getEvents(data) {
    return instance.post('/api/getevents', data);
  },
  getGps(data) {
    return instance.post('/api/getgps', data);
  },
};
