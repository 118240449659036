<template>
  <div class="dashboard">
    <v-row>
      <v-col xs="12" sm="6">
        <p style="margin-top:40px;font-size:32px;">
          {{ today($store.state.currentDate) }}
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            depressed
            style="margin:-6px 0px 0px 20px;backgroundColor:#C8E2FB;color:rgba(0,0,0,.4)"
            @click="$router.back()"
          >
            <v-icon>
              mdi-arrow-left-thin
            </v-icon>
            上一頁
          </v-btn>
          <v-menu
            ref="menu"
            v-model="pickerMenu"
            :close-on-content-click="false"
            :return-value.sync="pickerDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="margin-left:8px;"
                v-bind="attrs"
                v-on="on"
              >
                mdi-calendar
              </v-icon>
            </template>
            <v-date-picker
              color="#4DBCB8"
              v-model="pickerDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="#4DBCB8"
                @click="pickerMenu = false"
              >
                取消
              </v-btn>
              <v-btn
                text
                color="#4DBCB8"
                @click="firePicker"
              >
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <!-- <v-btn icon @click="nextDay">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn> -->
        </p>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
      <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp">
        <v-slider
          v-model="calendarHieght"
          step="40"
          ticks
          :max="200"
          :min="40"
          color="#4DBCB8"
          style="width:100%;margin-top:46px;"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="7" style="padding-top:0px;padding-bottom:0px;">
        <v-tabs
          style="width:90%;margin:auto;"
          v-model="tab"
          show-arrows
          @change="changeRegion"
        >
          <v-tabs-slider color="#4DBCB8"></v-tabs-slider>

          <v-tab
            v-for="area in areas"
            :key="area.name"
          >
            <v-badge
              color="#FFC130"
              :content="area.notify"
              :value="area.notify"
            >
              {{ area.name }}
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        sm="5"
        v-if="$vuetify.breakpoint.smAndUp"
        style="text-align:right;padding:0px;">
        <!-- <v-btn text style="font-size:18px;" @click="addLine">
          <v-icon style="color:#4DBCB8;margin-right:5px;">
            mdi-plus
          </v-icon>
          新增區域
        </v-btn> -->
        <span v-if="$store.state.userInfo.position !== 'viewer'">
        <v-btn text style="font-size:18px;" @click="lineDialog = true">
          <v-icon style="color:#4DBCB8;margin-right:5px;">
            mdi-archive-edit-outline
          </v-icon>
          管理路線
        </v-btn>
        <v-btn text style="font-size:18px;" @click="openUnbook">
          <v-icon style="color:#4DBCB8;margin-right:5px;">
            mdi-bell
          </v-icon>
          未指派任務
        </v-btn>
        <v-btn text style="font-size:18px;" @click="unbook">
          <v-icon style="color:#4DBCB8;margin-right:5px;">
            mdi-calendar-edit
          </v-icon>
          未定任務
        </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xs="12"
        :sm="calculateWidth"
        v-if="calculateWidth !== 0 && $vuetify.breakpoint.smAndUp"
        @click="hide"
        style="cursor:pointer;"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-card style="height:280px;overflow-y:scroll;">
                <v-card-text style="font-size:18px;">
                  <v-row>
                    <v-col cols="2">
                      <v-icon style="color:#4DBCB8;margin-left:4px;">mdi-account</v-icon>
                    </v-col>
                    <v-col cols="10">
                      今日人員
                    </v-col>
                  </v-row>
                <span v-for="worker in todayWorkers" :key="worker.name">
                  <v-row>
                    <v-col cols="2" style="padding:12px 0px 0px 12px;">
                      <v-img
                        v-if="worker.image != undefined"
                        :src="worker.image"
                        style="border-radius: 50%;width:28px;height:28px;"
                      >
                      </v-img>
                      <v-icon
                        v-else
                        style="color:#4DBCB8;font-size:30px;">
                        mdi-account-circle
                      </v-icon>
                    </v-col>
                    <v-col cols="3" style="padding:12px 0px 0px 12px;">
                      <div style="margin-top:4px;">{{ worker.name }}</div>
                    </v-col>
                    <v-col cols="4">
                      <v-progress-linear
                        :value="worker.finished/60*12.5"
                        :buffer-value="worker.planed/60*12.5"
                        height="25"
                        style="border: 1px solid ;border-radius: 4px;padding:0px"
                        :color="worker.planed/60>8?'red':'#4DBCB8'"
                        :style="worker.planed/60>8?'border-color:red':'border-color:#4DBCB8'"
                        change="doNothing"
                      >
                        <strong>
                          <!-- {{Math.round(worker.finished / worker.total * 10000) / 100.00}} % -->
                        </strong>
                      </v-progress-linear>
                      <!-- <v-progress-linear
                        v-else
                        :value="worker.percent"
                        height="25"
                        color="red"
                      >
                        <strong>
                          {{Math.round(worker.finished / worker.total * 10000) / 100.00}} %
                        </strong>
                      </v-progress-linear> -->
                    </v-col>
                    <v-col cols="3" style="padding:12px 0px 0px 0px;text-align:center">
                      <span style="font-size:16px;">
                        {{ (worker.finished/60).toFixed(1) }}/{{ (worker.planed/60).toFixed(1) }}
                      </span>
                    </v-col>
                  </v-row>
                </span>
                </v-card-text>
              </v-card>
              <v-card style="height:220px;overflow-y:scroll;margin-top:30px;">
                <v-card-text style="font-size:18px;">
                  <v-row style="line-height: 70px;">
                    <v-col cols="2">
                      <v-icon style="color:#4DBCB8">mdi-clock-outline</v-icon>
                    </v-col>
                    <v-col cols="4">
                      可排工時
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                    <v-col cols="3" style="text-align: right;">
                      {{ totalSum.toFixed(1) }}
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row style="line-height: 60px;">
                    <v-col cols="2">
                    </v-col>
                    <v-col cols="4">
                      排訂工時
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                    <v-col cols="3" style="text-align: right;">
                      <!-- {{ planedSum }} -->
                      {{ totalPlan.toFixed(1) }}
                    </v-col>
                  </v-row>
                  <!-- <v-row style="margin-top:-10px;margin-bottom:-26px;">
                    <v-col cols="2">
                    </v-col>
                    <v-col cols="4">
                      正常
                    </v-col>
                    <v-col cols="3" style="text-align: right;">
                      {{ regularSum }}
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                  </v-row>
                  <v-row style="line-height: 30px;">
                    <v-col cols="2">
                    </v-col>
                    <v-col cols="4">
                      加班
                    </v-col>
                    <v-col cols="3" style="text-align: right;">
                      {{ (overtimeSum / 60).toFixed(1) }}
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                  </v-row> -->
                  <v-divider></v-divider>
                  <v-row style="line-height: 60px;">
                    <v-col cols="2">
                    </v-col>
                    <v-col cols="4">
                      實際工時
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                    <v-col cols="3" style="text-align: right;">
                      {{ totalDuration.toFixed(1) }}
                      <!-- {{ (finishedSum / 60).toFixed(1) }} -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </span>
          </template>
          <span style="background-color:grey;padding:10px;opacity:.6;border-radius: 8px;">
            <span style="font-size:20px;">點選可隱藏</span>
          </span>
          <!-- <v-icon
          class="arrow">
            mdi-chevron-right
          </v-icon> -->
        </v-tooltip>
      </v-col>
      <v-col xs="12" :sm="calenderWidth">
        <div style="margin:-220px 0px -304px -75px;" v-if="calculateWidth == 0">
          <v-icon
            style="padding-top:484px;"
            class="arrow"
            @click="show"
          >
            mdi-chevron-right
          </v-icon>
        </div>
        <v-sheet height="600" style="overflow-x:scroll;width:100%">
          <v-calendar
            style="overflow-x:scroll;"
            ref="calendar"
            v-model="focus"
            color="#4DBCB8"
            type="category"
            category-show-all
            :interval-height="calendarHieght"
            :categories="newCategories"
            category-text="name"
            :event-category="fireCategories"
            :events="events"
            @click:event="getEvents"
            @mousedown:event="startDrag"
            @mousedown:time-category="startTime"
            @mousemove:time-category="mouseMove"
            @mouseup:time-category="endDrag"
          >
            <template v-slot:day-header="{ category }">
              <div
                style="text-align:center;"
              >
                <span v-if="category.note.length > 0">({{category.note}})</span>
              </div>
            </template>
            <template v-slot:day-body="{ date, category }">
              <div
                class="v-current-time"
                :class="{ first: category.name === 'A路線' }"
                :style="`top: ${nowY}`"
              ></div>
            </template>
            <template v-slot:event="{ event, eventParsed}">
              <!-- <div
                style="border:2px black solid"
                :style="{ top: nowY }"
              ></div> -->
              <!-- <div style="border-bottom:4px black solid;height:100%;padding:5px;"> -->
              <v-tooltip bottom :color="event.color">
                <template v-slot:activator="{ on, attrs }">
                  <div style="height:100%;padding:5px;overflow:hidden" v-bind="attrs" v-on="on">
                    <!-- {{event.member}} -->
                    <span style="font-size:14px;">
                      {{event.location}}{{event.dist}}{{event.address}}<br>
                    </span>
                    <span style="font-size:14px;">
                      客編：{{event.clientId}}
                      <span style="font-size:12px;">
                        {{eventParsed.start.time}}-{{eventParsed.end.time}}
                      </span>
                    </span>
                    <br>
                    <span style="font-size:14px;">
                      {{event.clientName}}
                    </span>
                    <br>
                    <span style="font-size:14px;">
                      單編：{{event.sheetId}}<br>
                    </span>
                    <span style="font-size:14px;" v-for="worker in event.member" :key="worker.name">
                      {{worker.name}}
                    </span>
                    <span style="font-size:14px;">
                      <span v-for="task in event.task" :key="task.name">{{task.name}},</span>
                    </span>
                    <br>
                    <span style="font-size:14px;" v-if="event.history">
                      建單人：{{event.history[0].name}}
                    </span>
                  </div>
                </template>
                <span style="font-size:14px;">
                  {{eventParsed.start.time}}-{{eventParsed.end.time}}
                </span>
                <br>
                <span style="font-size:14px;" v-for="worker in event.member" :key="worker.name">
                  {{worker.name}}
                </span>
                <br>
                <span style="font-size:14px;">
                  {{event.location}}{{event.dist}}{{event.address}}
                </span>
                <br>
                <span style="font-size:14px;">
                  單編：{{event.sheetId}}
                </span>
                <br>
                <span style="font-size:14px;">
                  客編：{{event.clientId}} | {{event.clientName}}
                </span>
                <br>
                <span style="font-size:14px;" v-if="event.history">
                  建單人：{{event.history[0].name}}
                </span>
              </v-tooltip>
              <!-- <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div> -->
              <v-progress-linear
                v-if="event.arrived && !(event.finished)"
                indeterminate
                :color="event.color"
              ></v-progress-linear>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="860"
      @click:outside="outsideEdit"
    >
      <v-card style="padding:20px;">
        <!-- <v-row v-show="edit"> -->
        <v-row>
          <v-col cols="4">
            <span style="font-size:18px">指派任務</span>
          </v-col>
          <v-col cols="8">
            <div style="text-align:right;">
              <v-icon @click="cancelEdit">
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <div style="max-height:500px;overflow:scroll;padding:20px 12px;">
        <v-row
          v-for="taskDetail in taskHead"
          :key="taskDetail.label"
        >
          <v-col cols="1" style="padding:6px;">
            <v-icon style="color:#4DBCB8;">
              {{taskDetail.icon}}
            </v-icon>
          </v-col>
          <v-col cols="2" style="padding:6px;">
            {{ taskDetail.label }}
          </v-col>
          <v-col cols="9" style="padding:11px;">
            <v-form
              ref="myForm"
            >
              <v-select
                v-if="taskDetail.value == 'region'"
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                :label="newTask[taskDetail.value]"
                outlined
                disabled
                dense
              ></v-select>
              <v-text-field
                v-else-if="taskDetail.value == 'sheetId'"
                style="margin:-16px 0px"
                v-model.trim="newTask[taskDetail.value]"
                label="施作單號"
                outlined
                dense
                @keydown.enter.prevent="tabEvent"
                @blur="updateEvent"
                :disabled="!edit"
              ></v-text-field>
              <span v-else-if="taskDetail.value == 'clientInfo'">
                <v-text-field
                  style="margin:-16px 0px"
                  v-model.trim="newTask.clientName"
                  label="業主名稱"
                  outlined
                  dense
                  @keydown.enter.prevent="tabEvent"
                  @blur="updateEvent"
                  :disabled="!edit"
                ></v-text-field>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      style="margin:-16px 0px"
                      v-model.trim="newTask.clientId"
                      label="業主編號"
                      outlined
                      dense
                      @keydown.enter.prevent="tabEvent"
                      @blur="updateEvent"
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      style="margin:-16px 0px"
                      v-model.trim="newTask.clientTel"
                      label="聯絡電話"
                      outlined
                      dense
                      @keydown.enter.prevent="tabEvent"
                      @blur="updateEvent"
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </span>
              <v-select
                v-else-if="taskDetail.value == 'member'"
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                :items="todayWorkers"
                label="施作師傅"
                outlined
                item-text="name"
                item-value="name"
                return-object
                :disabled="!edit"
                multiple
                dense
                @blur="workerCal(newTask)"
              ></v-select>
              <v-select
                v-else-if="taskDetail.value == 'task'"
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                :items="$store.state.abilities"
                label="施作任務"
                outlined
                item-text="name"
                item-value="name"
                return-object
                :disabled="!edit"
                dense
                multiple
                readonly
                ref="taskRef"
                @focus="taskChoose($event, 'task')"
                @blur="taskBlur"
              ></v-select>
              <v-textarea
                v-else-if="taskDetail.value == 'brief'"
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                outlined
                dense
                auto-grow
                :disabled="!edit"
                rows="1"
                @blur="updateEvent"
              ></v-textarea>
              <p v-else-if="taskDetail.value == 'start'" style="margin-top:-6px">
                {{new Date(newTask.start)}}
              </p>
              <p v-else-if="taskDetail.value == 'end'" style="margin-top:-6px">
                {{new Date(newTask.end)}}
                <v-menu
                  v-model="changeDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="#4DBCB8"
                      dark
                      :disabled="!edit || newTask.id === undefined"
                      v-bind="attrs"
                      v-on="on"
                      style="height:80px;margin-top:-56px;float:right;width:140px;">
                      更換日期
                    </v-btn>
                  </template>
                  <v-date-picker
                    color="#4DBCB8"
                    locale="zh-tw"
                    v-model="pickDate"
                    @input="moveEventToDate"
                  ></v-date-picker>
                </v-menu>
              </p>
              <span v-else-if="taskDetail.value == 'location'">
                <v-row>
                  <v-col cols="6">
                    <v-select
                      style="margin:-16px 0px;"
                      v-model="newTask.location"
                      item-text="name"
                      :items="taiwan"
                      label="縣市"
                      outlined
                      :disabled="!edit"
                      dense
                      @keydown.enter.prevent="tabEvent"
                      @change="setDist(newTask.location)"
                      @blur="updateEvent"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-if="taiwan.find((ele) => ele.name === newTask.location) === undefined"
                      style="margin:-16px 0px;"
                      v-model="newTask.dist"
                      item-text="name"
                      :items="districts"
                      label="區"
                      outlined
                      dense
                      @keydown.enter.prevent="tabEvent"
                      @blur="updateEvent"
                      :disabled="!edit && newTask.location !== undefined"
                    ></v-select>
                    <v-select
                      v-else
                      style="margin:-16px 0px;"
                      v-model="newTask.dist"
                      item-text="name"
                      :items="taiwan.find((ele) => ele.name === newTask.location)['districts']"
                      label="區"
                      outlined
                      dense
                      @keydown.enter.prevent="tabEvent"
                      @blur="updateEvent"
                      :disabled="!edit && newTask.location !== undefined"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-text-field
                  v-model.trim="newTask.address"
                  outlined
                  dense
                  :disabled="!edit"
                  @keydown.enter.prevent="tabEvent"
                  @blur="updateEvent"
                ></v-text-field>
              </span>
              <v-select
                v-else-if="taskDetail.value == 'traffic'"
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                :items="$store.state.traffics"
                label="加給路程"
                outlined
                item-text="name"
                item-value="name"
                return-object
                :disabled="!edit"
                multiple
                dense
                readonly
                @focus="taskChoose($event, 'traffic')"
              ></v-select>
              <v-select
                v-else
                style="margin:-16px 0px"
                v-model="newTask[taskDetail.value]"
                :items="items"
                :label="newTask[taskDetail.value]"
                outlined
                :disabled="!edit"
              ></v-select>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div v-for="ta in newTask.task" :key="ta.name">
              拍照內容({{ ta.name }})：
              <span v-for="(img,index) in ta.images" :key="img">
                {{ img }}
                <span v-if="index !== ta.images.length - 1">、</span>
              </span>
              (
              <span v-if="newTask.images !== undefined">{{newTask.images.length}}</span>
              <span v-else>0</span>
              /
              <span v-if="ta.images !== undefined">{{ta.images.length}}</span>
              <span v-else>0</span>
              )
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" v-for="image in newTask.images" :key="image">
            <v-img
              style="width:100%;cursor:pointer;"
              :src="`https://storage.googleapis.com/shihleicloud/${newTask.id}/${image}.jpg`"
              @click="goPic(`https://storage.googleapis.com/shihleicloud/${newTask.id}/${image}.jpg`)"
            ></v-img>
          </v-col>
        </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <div v-if="newTask.arrived !== undefined">
              到達時間：{{new Date(newTask.arrived.time)}}
              <v-icon @click="goMap(newTask.arrived)">
                mdi-map-marker
              </v-icon>
            </div>
            <div v-if="newTask.finished !== undefined">
              完成時間：{{new Date(newTask.finished.time)}}
              <v-icon @click="goMap(newTask.finished)">
                mdi-map-marker
              </v-icon>
            </div>
            <div v-if="newTask.comments !== undefined" style="opacity:0.8">
              備註：{{newTask.comments}}
            </div>
          </v-col>
        </v-row>
        <div style="opacity:0.6">
          圖片(標準安裝作業)主管評分1~10分：得{{newTask.imgScore}}分，{{newTask.imgComments}}
          <v-icon
            v-if="$store.state.userInfo.position === 'director' ||
            $store.state.userInfo.position === 'admin'"
            @click="imgModifyDialog = true"
          >
            mdi-image-edit-outline
          </v-icon>
        </div>
        <span style="opacity:0.6">
          預計總時程{{newTask.totalPlaned}}分鐘，總計積分{{newTask.totalScore}}
          <span v-if="newTask.modifyScore !== undefined">
            調整後積分為 {{newTask.totalScore + Number(newTask.modifyScore)}}
          </span>
          <v-icon
            v-if="$store.state.userInfo.position === 'director' ||
            $store.state.userInfo.position === 'admin'"
            @click="modifyDialog = true"
          >
            mdi-pen
          </v-icon>
        </span>
        <div style="text-align:center;margin-top:20px;">
          <span v-if="newTask.id == undefined">
            <span v-if="typeof(newTask.start) === 'number'">
            <!-- <span v-if="newTask.start.length === undefined"> -->
              <v-btn
                v-if="edit && ableToCreate"
                style="width:220px;height:50px;"
                color="#4DBCB8"
                dark
                @click="readyToCreate"
              >
                <span style="font-size:18px;">完成</span>
              </v-btn>
            </span>
            <span v-else>
              <v-btn
                v-if="edit && ableToCreate"
                style="width:220px;height:50px;"
                color="#4DBCB8"
                dark
                @click.once="unbookedCreate"
              >
                <span style="font-size:18px;">新增</span>
              </v-btn>
            </span>
          </span>
          <span v-else>
            <!-- <v-btn
              v-if="edit"
              style="width:220px;height:50px;margin-left:10px;"
              color="red"
              dark
              @click="removeEvent(newTask)"
            >
              <span style="font-size:18px;">刪除</span>
            </v-btn> -->
            <span v-if="edit">
              <v-row>
                <v-spacer />
                <v-col cols="8" style="padding:0px;">
                  <div style="color:#4DBCB8">
                    <span>系統自動儲存</span>
                    <v-progress-circular
                      v-show="uploading"
                      :size="20"
                      :width="2"
                      indeterminate
                      color="#4DBCB8"
                      style="margin:0px 0px 4px 8px"
                    ></v-progress-circular>
                  </div>
                </v-col>
                <v-col cols="2" style="padding:0px;">
                  <v-btn
                    fab
                    color="#4DBCB8"
                    dark
                    style="margin:-10px 20px 0px 0px;"
                    elevation="0"
                    @click="copyEvent(newTask)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    color="red"
                    dark
                    style="margin-top:-10px;"
                    elevation="0"
                    @click="removeEvent(newTask)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </span>
            <span v-else>
              <v-btn
                v-if="$store.state.userInfo.position !== 'viewer'"
                style="width:250px;height:50px;margin-right:60px;"
                color="#4DBCB8"
                dark
                @click="edit = true"
              >
                <span style="font-size:18px;">編輯</span>
              </v-btn>
              <v-btn
                style="width:250px;height:50px;"
                color="#4DBCB8"
                outlined
                @click="dialog = false"
              >
                <span style="font-size:18px;">關閉</span>
              </v-btn>
            </span>
          </span>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modifyDialog"
      width="600"
      @click:outside="closeModifyDialog"
    >
      <v-card style="padding:20px;height:326px;overflow:scroll">
        <v-row>
          <v-col cols="3">
            <p style="margin:18px 0px 0px 20px">積分調整</p>
          </v-col>
          <v-col cols="9">
            <v-text-field
              type="number"
              v-model="newTask.modifyScore"
              outlined
            />
          </v-col>
        </v-row>
        <v-textarea
          v-model="newTask.modifyReason"
          label="請輸入積分調整理由"
          outlined
          auto-grow
          rows="5"
        >
        </v-textarea>
        <v-row>
          <v-col cols="12" style="text-align:center">
            <v-btn color="#4DBCB8" @click="modifyConfirm">
              <span style="color:white">確定調整</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imgModifyDialog"
      width="600"
      @click:outside="closeImgModifyDialog"
    >
      <v-card style="padding:20px;height:326px;overflow:scroll">
        <v-row>
          <v-col cols="3">
            <p style="margin:18px 0px 0px 20px">圖片主管評分</p>
          </v-col>
          <v-col cols="9">
            <v-slider
              style="margin-top:14px;"
              color="#4DBCB8"
              v-model="newTask.imgScore"
              :step="1"
              :max="10"
              :min="0"
              thumb-label
              ticks
            >
              <template v-slot:append>
                <p style="margin-top:4px;">{{ newTask.imgScore }}</p>
                <!-- <v-text-field
                  v-model="newTask.imgScore"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  disabled
                  type="number"
                  style="width: 60px"
                ></v-text-field> -->
              </template>
            </v-slider>
            <!-- <v-text-field
              type="number"
              v-model="newTask.imgScore"
              outlined
            /> -->
          </v-col>
        </v-row>
        <v-textarea
          v-model="newTask.imgComments"
          label="請輸入須改善的要求"
          outlined
          auto-grow
          rows="5"
        >
        </v-textarea>
        <v-row>
          <v-col cols="12" style="text-align:center">
            <v-btn color="#4DBCB8" @click="imgModifyConfirm">
              <span style="color:white">確定調整</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unbookDialog"
      width="1000"
      @click:outside="closeUnbookDialog"
    >
      <v-card style="padding:20px;height:660px;overflow:scroll">
        <v-tabs color="#4DBCB8" style="margin-bottom:10px;">
          <v-tab v-for="tab in unbookTab" :key="tab.label">
            {{ tab.label }}
          </v-tab>
        </v-tabs>
        <v-row>
          <v-col cols="5">
            <div
              class="bookCard"
              style="max-height:500px;overflow:scroll;">
              <v-card
                style="margin-top:4px;"
                v-for="wait in waiting"
                :key="wait.id">
                <v-card-text style="font-size:18px;">
                  <v-row>
                    <v-col cols="3">
                      <v-icon>mdi-city</v-icon>
                      區域
                    </v-col>
                    <v-col cols="9">
                      <span>
                        {{ wait.region }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-icon>mdi-clipboard-outline</v-icon>
                      任務
                    </v-col>
                    <v-col cols="9">
                      <span v-for="ta in wait.task" :key="ta.name">
                        {{ ta.name }}
                      </span>
                      <div style="font-size:14px;">
                        預計工時{{wait.plan}}分鐘  積分{{wait.score}}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-icon>mdi-account</v-icon>
                      人員
                    </v-col>
                    <v-col cols="9">
                      <span v-for="wo in wait.work" :key="wo.name">
                        {{ wo.name }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-spacer />
                    <v-col cols="4">
                      <v-btn
                        style="width:100px;"
                        outlined
                        color="#4DBCB8"
                        @click="bookUnbook(wait)"
                      >
                        指派
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </div>
            <div style="text-align:center;margin-top:30px;">
              <v-btn style="width:120px;" color="#4DBCB8" dark @click="closeUnbookDialog">
                關閉
              </v-btn>
            </div>
          </v-col>
          <v-col cols="7">
            <v-card v-show="currentUnbookTask.region != undefined">
              <div
                style="text-align:center;"
              >
                <v-time-picker
                  color="#4DBCB8"
                  v-model="taskNewTime"
                  format="ampm"
                  style="width:96%;height:300px;margin:10px auto;"
                  landscape
                  elevation="1"
                ></v-time-picker>
              </div>
              <div style="text-align:center;margin-top:30px;">
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      style="width:160px;margin-left:6px;"
                      color="#4DBCB8"
                      dark
                      outlined
                      @click="confirmTime"
                    >
                      確認開始時間
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col cols="4">
                    <v-btn
                      small
                      fab
                      color="red"
                      dark
                      outlined
                      style="margin-top:-4px;"
                      elevation="0"
                      @click="removeEvent(currentUnbookTask)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="selectDialog"
      width="600"
      @click:outside="closeSelectDialog"
    >
     <v-card style="height:400px;padding:8px;overflow:scroll;">
       <v-treeview
        v-if="taskKind === 'task'"
        v-model="abilityArray"
        selectable
        @input="taskSelect"
        :items="$store.state.taskCategories">
       </v-treeview>
       <v-treeview
        v-else
        v-model="trafficArray"
        selectable
        @input="taskSelect"
        :items="$store.state.trafficCategories">
       </v-treeview>
     </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="lineDialog"
      @click:outside="updateLines"
      width="600"
    > -->
    <v-dialog
      v-model="lineDialog"
      width="680"
      @click:outside="checkDialog = true"
    >
      <v-card style="height:460px">
        <v-row style="margin-bottom:-40px;">
          <v-col cols="12">
            <v-icon
              size="40"
              style="float:right;margin: 6px"
              @click="lineDialog = false"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <div style="height:310px;padding-top:40px;overflow:scroll">
          <v-row v-for="category in newCategories" :key="category.name">
            <v-spacer />
            <v-col cols="2" style="padding:0px;">
              <p style="padding-top:16px;">
                {{ category.name }}
              </p>
            </v-col>
            <v-col cols="8" style="padding:0px;">
              <v-text-field v-model.trim="category.note" outlined>
              </v-text-field>
            </v-col>
            <v-spacer />
          </v-row>
        </div>
        <v-row style="height:80px;margin-top:10px;border: 2px solid grey top;">
          <v-spacer />
          <v-col cols="5" style="text-align:left">
            <v-btn color="#4DBCB8" outlined x-large style="width:90%" @click="addLine">
              增加路線
            </v-btn>
          </v-col>
          <v-col cols="5" style="text-align:right">
            <v-btn color="#BC5156" outlined x-large style="width:90%" @click="reduceLine">
              減少路線
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkDialog"
      width="380"
    >
      <v-card style="height:90px">
        <v-row style="height:80px;padding-top:12px;border: 2px solid grey top;">
          <v-spacer />
          <v-col cols="5" style="text-align:left">
            <v-btn
              color="#4DBCB8" outlined x-large style="width:90%" @click="updateLines('today')">
              只變更今日路線
            </v-btn>
          </v-col>
          <v-col cols="5" style="text-align:right">
            <v-btn
              color="#BC5156" outlined x-large style="width:90%" @click="updateLines('future')">
              包含未訂路線
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dragDialog" width="400">
      <v-card>
        <v-row style="padding: 10px 0px">
          <v-spacer />
          <v-col cols="5">
            <v-btn color="#4DBCB8" style="width:100%" outlined @click="saveDrag">
              確定
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="5">
            <v-btn color="grey" style="width:100%" outlined @click="backDrag">
              取消
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="lineBar"
      color="#4DBCB8"
    >
      <span style="font-size:18px">{{ lineInfo }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="lineBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar"
      color="#4DBCB8"
      text
    >
      <strong style="font-size:20px;">請改用tab按鍵換行</strong>
    </v-snackbar>
  </div>
</template>

<script>
import api from '../api/detail';
import json from '../assets/taiwan.json';
import { db } from '../db';

export default {
  name: 'Detail',
  data: () => ({
    imgModifyDialog: false,
    lineBar: false,
    lineInfo: null,
    checkDialog: false,
    modifyScore: 0,
    modifyReason: '',
    modifyDialog: false,
    pickerDate: '',
    pickerMenu: false,
    totalPlan: 0,
    totalDuration: 0,
    dragDialog: false,
    lineDialog: false,
    fab: false,
    taskNewTime: null,
    snackbar: false,
    uploading: false,
    taskKind: 'task',
    selectDialog: false,
    tempArr: [],
    tempTrafficArr: [],
    abilityArray: [],
    trafficArray: [],
    currentPerson: {},
    changeDate: false,
    currentUnbookTask: {},
    simpleRules: [
      (v) => !!v || '請輸入資訊',
    ],
    valid: true,
    calculateWidth: 4,
    calenderWidth: 8,
    districts: [],
    taiwan: json,
    edit: false,
    value: '',
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    waiting: [
      // {
      //   task: '任務B',
      //   plan: 45,
      //   score: 2,
      //   member: '林師傅、王師傅',
      // }, {
      //   task: '任務C',
      //   plan: 100,
      //   score: 5,
      //   member: '徐師傅、黃師傅',
      // },
    ],
    unbookTab: [
      {
        label: '今日待指派任務',
        value: 'unbook',
      }, {
        label: '未結案',
        value: 'undone',
      },
    ],
    unbookDialog: false,
    taskHead: [
      {
        icon: 'mdi-city',
        label: '區域',
        value: 'region',
      }, {
        icon: 'mdi-list-box-outline',
        label: '單據編號',
        value: 'sheetId',
      }, {
        icon: 'mdi-account-box-outline',
        label: '客戶資料',
        value: 'clientInfo',
      }, {
        icon: 'mdi-clipboard-outline',
        label: '任務',
        value: 'task',
      }, {
        icon: 'mdi-file-document-edit-outline',
        label: '任務概述',
        value: 'brief',
      }, {
        icon: 'mdi-account',
        label: '人員',
        value: 'member',
      }, {
        icon: 'mdi-clock',
        label: '開始時間',
        value: 'start',
      }, {
        icon: '',
        label: '結束時間',
        value: 'end',
      }, {
        icon: 'mdi-map-marker',
        label: '地點',
        value: 'location',
      }, /* {
        icon: 'mdi-car-estate',
        label: '路程積分',
        value: 'traffic',
      }, */
    ],
    task: {
      region: '北區',
      task: '安裝類 > 濾水器安裝 > 家用氣泡水 > YS-120(CUML35.3)',
      member: '陳師傅、張師傅',
      start: '6月23(星期四)',
      end: '6月23(星期四)',
      location: '台北市中正區',
      traffic: '5',
    },
    newTask: {
      region: '',
      task: [],
      member: [],
      start: '',
      end: '',
      location: '',
      category: {},
      traffic: [],
    },
    dialog: false,
    tab: 0,
    focus: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    categories: ['A路線', 'B路線', 'C路線'],
    newCategories: [],
    areas: [],
    todayWorkers: [],
    workers: [
      {
        name: '王師傅',
        finished: 2,
        total: 8,
        percent: 25,
      }, {
        name: '李師傅',
        finished: 2,
        total: 8,
        percent: 25,
      }, {
        name: '陳師傅',
        finished: 9,
        total: 8,
        percent: 112.5,
      }, {
        name: '林師傅',
        finished: 5,
        total: 8,
        percent: 62.5,
      }, {
        name: '黃師傅',
        finished: 8,
        total: 8,
        percent: 100,
      },
    ],
    items: [],
    abilities: [],
    traffics: [],
    gonnaCreateEvent: {},
    ableToCreate: true,
    pickDate: null,
    calendarHieght: 80,
    ready: false,
    beforeDragStart: null,
    beforeDragEnd: null,
    beforeDragCategory: null,
    planedSum: 0,
    eventListener: null,
    defaultAreas: [],
  }),
  mounted() {
    this.ready = true;
    this.updateTime();
    this.$refs.calendar.checkChange();
    const calYear = this.$route.params.date.slice(0, 4);
    const calMonth = this.$route.params.date.slice(4, 6);
    const calDay = this.$route.params.date.slice(6, 8);
    this.focus = new Date(`${calYear}-${calMonth}-${calDay}`);
    this.pickDate = `${calYear}-${calMonth}-${calDay}`;
    this.getRequireData();
    this.scrollToTime(this.getCurrentTime());
  },
  computed: {
    permissionEdit() {
      let result = true;
      if (this.$store.state.userInfo.region === '總部') {
        result = true;
      } else {
        result = this.$store.state.userInfo.region === this.newTask.region;
      }
      console.log(this.newTask, 'PPPPMMMMMMMMMM!!', this.$store.state.userInfo.region);
      return result;
    },
    workerList() {
      const arr = [];
      this.todayWorkers.forEach((ele) => {
        arr.push(ele.name);
      });
      return arr;
    },
    totalSum() {
      return this.todayWorkers.length * 8;
    },
    finishedSum() {
      let result = 0;
      this.todayWorkers.forEach((ele) => {
        if (typeof (ele.finished) === 'number') {
          result += ele.finished;
        } else {
          result = 0;
        }
        console.log('pppp', result);
      });
      return result;
    },
    overtimeSum() {
      let result = 0;
      this.todayWorkers.forEach((ele) => {
        if (ele.finished > ele.total) {
          result = result + ele.finished - ele.total;
        }
      });
      return result;
    },
    cal() {
      return this.$refs.calendar;
    },
    nowY() {
      return this.cal ? `${this.cal.timeToY(this.cal.times.now)}px` : '-10px';
    },
  },
  methods: {
    scrollToTime(time) {
      const y = this.cal.timeToY(time);
      if (y === false || !this.cal.$el) {
        return false;
      }
      this.cal.$el.scrollTop = y;
      this.$nextTick(() => {
        setTimeout(() => {
          const el = this.$refs.calendar.$el;
          el.scrollTop = y;
        }, 600);
      });
      return !!this.cal.$el.scrollTop;
    },
    getCurrentTime() {
      return 705;
    },
    goPic(url) {
      window.open(url);
    },
    nextDay() {
      this.$refs.calendar.next();
      window.location.reload();
    },
    imgModifyConfirm() {
      this.imgModifyDialog = false;
      this.updateEvent();
    },
    modifyConfirm() {
      this.modifyDialog = false;
      this.updateEvent();
    },
    closeImgModifyDialog() {
      this.newTask.imgScore = 0;
      this.newTask.imgComments = '';
      this.imgModifyDialog = false;
    },
    closeModifyDialog() {
      this.newTask.modifyScore = 0;
      this.newTask.modifyReason = '';
      this.modifyDialog = false;
    },
    async getRequireData() {
      const year = Number(this.$route.params.date.slice(0, 4));
      const month = Number(this.$route.params.date.slice(4, 6));
      const date = Number(this.$route.params.date.slice(6, 8));
      const { region } = this.$store.state;
      await this.mounteAreas();
      if (this.eventListener) {
        this.eventListener(); // 移除之前的监听器
      }
      this.$set(this, 'events', []); // 重置事件数组
      await Promise.all([
        this.mounteLines(year, month, date, region),
        this.mounteWorkers(region),
        this.mounteEvent(year, month, date, region), // 确保所有数据加载完毕
      ]);
    },
    mounteAreas() {
      console.log('fire Area');
      const docRef = db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g');
      docRef.get().then((doc) => {
        if (doc.exists) {
          doc.data().areas.forEach((area, index) => {
            this.$set(this.areas, index, {
              name: area.name,
              notify: 0,
              categories: area.categories,
            });
            this.$set(this.defaultAreas, index, {
              name: area.name,
              categories: area.categories,
            });
            if (area.name === this.$store.state.region) {
              this.tab = index;
            }
          });
          console.log('mounte areas document data:', doc.data());
        } else {
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    },
    mounteWorkers(region) {
      console.log('fire Workers');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('accounts')
        .where('region', '==', region)
        .onSnapshot(async (snapshot) => {
          await this.$set(this, 'todayWorkers', []);
          if (snapshot.empty) {
            console.log('No accounts in mounteWorkers');
          } else {
            await snapshot.forEach((change) => {
              // this.$set(this.todayWorkers, index, change.data());
              if (change.data().deactived !== true) {
                this.todayWorkers.push({
                  name: change.data().name,
                  planed: 0,
                  finished: 0,
                  color: change.data().color,
                  image: change.data().image,
                });
              }

              // console.log(index, 'getWorkers : ', change.data());
            });
          }
          console.log('end workers', this.todayWorkers);
        });
    },
    mounteLines(year, month, date, region) {
      console.log('fire Line');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('dateInfo')
        .where('year', '==', year)
        .where('month', '==', month)
        .where('date', '==', date)
        .onSnapshot(async (snapshot) => {
          const found = await this.areas.find((ele) => ele.name === region);
          await console.log('fire mounteLLLLEEEEE', this.areas, region);
          if (snapshot.empty) {
            console.log('EEEEEEEmpty', found);
            if (found.categories.length > 0) {
              this.$set(this, 'newCategories', found.categories);
            } else {
              this.$set(this, 'newCategories', [
                {
                  name: 'A路線',
                  note: '',
                }, {
                  name: 'B路線',
                  note: '',
                },
              ]);
            }
          } else {
            snapshot.forEach((change) => {
              const newFound = change.data().areas.find((ele) => ele.name === region);
              console.log('CCCCCCCCCC', newFound, found, change.data(), region);
              if (newFound === undefined) {
                this.$set(this, 'newCategories', found.categories);
              } else {
                console.log('SSSS', newFound, found, change.data(), region);
                if (newFound.categories === undefined) {
                  console.log('lalalala', found, this.areas);
                  if (found.categories.length === 0) {
                    this.$set(this, 'newCategories', [
                      {
                        name: 'A路線',
                        note: '',
                      }, {
                        name: 'B路線',
                        note: '',
                      },
                    ]);
                  } else {
                    console.log('found.categories.length elseeeee');
                    this.$set(this, 'newCategories', found.categories);
                  }
                } else {
                  console.log('nono', found, region, this.areas, newFound);
                  if (newFound.categories.length === 0) {
                    if (found.categories.length === 0) {
                      this.$set(this, 'newCategories', [
                        {
                          name: 'A路線',
                          note: '',
                        }, {
                          name: 'B路線',
                          note: '',
                        },
                      ]);
                    } else {
                      this.$set(this, 'newCategories', found.categories);
                    }
                  } else {
                    this.$set(this, 'newCategories', newFound.categories);
                  }
                }
              }
              console.log('Having data', newFound.categories, found);
            });
            console.log('fire mounteLLLL', this.newCategories);
            // console.log('Having data', change.data());
          }
        });
    },
    async mounteEvent(year, month, date, region) {
      console.log('fire Events');
      this.totalPlan = await 0;
      this.totalDuration = await 0;
      const todayTime = await new Date(year, month - 1, date);
      this.eventListener = await db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('start', '>=', todayTime.getTime())
        .where('start', '<', todayTime.getTime() + 86400000)
        .where('actived', '==', true)
        .where('region', '==', region)
        .onSnapshot(async (snapshot) => {
          console.log('SSSSSSEEEEEESSSSS', snapshot.empty, this.defaultAreas);
          this.totalPlan = await 0;
          await this.$set(this, 'events', []);
          await this.todayWorkers.forEach((worker) => {
            this.$set(worker, 'planed', 0);
            this.$set(worker, 'finished', 0);
          });
          await snapshot.forEach((change) => {
            this.events.push(change.data());
            change.data().member.forEach((ele) => {
              change.data().task.forEach((e) => {
                const duration = Number(change.data().duration);
                if (change.data().duration !== undefined) {
                  this.totalDuration += Number((duration / 3600000).toFixed(1));
                }
                const find = this.todayWorkers.find((el) => el.name === ele.name);
                if (find === undefined) {
                  console.log('something wrong');
                } else {
                  find.planed += Number(e.planed);
                  this.totalPlan += Number(e.planed) / 60;
                  if (change.data().finished !== undefined) {
                    find.finished += Number(e.planed);
                  }
                }
              });
            });
          });
        });
    },
    async changeRegion() {
      console.log('fire change r', this.areas[this.tab].name);
      const year = Number(this.$route.params.date.slice(0, 4));
      const month = Number(this.$route.params.date.slice(4, 6));
      const date = Number(this.$route.params.date.slice(6, 8));
      if (this.$store.state.region !== this.areas[this.tab].name) {
        await this.$set(this.$store.state, 'region', this.areas[this.tab].name);
        // this.$store.state.region = await this.areas[this.tab].name;
        await this.mounteAreas();
        await this.mounteLines(year, month, date, this.areas[this.tab].name);
        await this.mounteWorkers(this.areas[this.tab].name);
        await new Promise((resolve) => {
          this.$nextTick(() => {
            resolve();
          });
        });
        if (this.eventListener) {
          this.eventListener();
        }
        // await this.$set(this, 'events', []);
        this.eventListener = await this.mounteEvent(year, month, date, this.areas[this.tab].name);
      }
      // this.mounteWorkers(this.areas[this.tab].name);
    },
    goMap(geo) {
      console.log(geo);
      window.open(`https://www.google.com.tw/maps/place/${geo.lat},${geo.lon}`);
    },
    async firePicker() {
      const newDate = await this.pickerDate.replaceAll('-', '');
      await this.$router.push(`/detail/${newDate}`);
      window.location.reload();
      console.log('??????', newDate);
    },
    getLines() {
      const year = Number(this.$route.params.date.slice(0, 4));
      const month = Number(this.$route.params.date.slice(4, 6));
      const date = Number(this.$route.params.date.slice(6, 8));
      const { region } = this.$store.state;
      console.log('GGGLINE');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('dateInfo')
        .where('year', '==', year)
        .where('month', '==', month)
        .where('date', '==', date)
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            const docRef = db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g');
            docRef.get().then((doc) => {
              if (doc.exists) {
                const newFound = doc.data().areas.find((ele) => ele.name === region);
                if (newFound.categories !== undefined) {
                  this.newCategories = newFound.categories;
                } else {
                  this.newCategories = [
                    {
                      name: 'A路線',
                      note: '',
                    }, {
                      name: 'B路線',
                      note: '',
                    },
                  ];
                }
                console.log('Document data:', newFound);
              } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
              }
            }).catch((error) => {
              console.log('Error getting document:', error);
            });
            console.log('No matching documents.');
          } else {
            await snapshot.forEach((change) => {
              const found = change.data().areas.find((ele) => ele.name === region);
              console.log(found, 'FFFFFFFF');
              if (found.categories !== undefined) {
                this.newCategories = found.categories;
              } else {
                const docRef = db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g');
                docRef.get().then((doc) => {
                  if (doc.exists) {
                    const newFound = doc.data().areas.find((ele) => ele.name === region);
                    if (newFound.categories !== undefined) {
                      this.newCategories = newFound.categories;
                    } else {
                      this.newCategories = [
                        {
                          name: 'A路線',
                          note: '',
                        }, {
                          name: 'B路線',
                          note: '',
                        },
                      ];
                    }
                    console.log('Document data:', doc.data());
                  } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                  }
                }).catch((error) => {
                  console.log('Error getting document:', error);
                });
              }
              console.log(found, 'GGGGLINE');
            });
          }
        });
    },
    updateLines(kind) {
      const find = this.areas.find((ele) => ele.name === this.areas[this.tab].name);
      this.$set(find, 'categories', this.newCategories);
      console.log(this.areas, '||||||');
      api.updateLines({
        kind,
        year: Number(this.$route.params.date.slice(0, 4)),
        month: Number(this.$route.params.date.slice(4, 6)),
        date: Number(this.$route.params.date.slice(6, 8)),
        categories: this.newCategories,
        region: this.$store.state.region,
        areas: this.areas,
      })
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data, 'lines updateeeee');
            this.$set(this, 'checkDialog', false);
            this.$set(this, 'lineBar', true);
            this.$set(this, 'lineInfo', data.info);
          } else {
            console.log('err:', data);
          }
        });
      console.log(this.areas, 'LLLLLLL');
    },
    tableHeadIn(head) {
      console.log(head);
      this.$set(head, 'show', true);
    },
    tableHeadOut(head) {
      this.$set(head, 'show', false);
    },
    fireCategories(event) {
      return event.category.name;
    },
    async confirmTime() {
      const year = this.$route.params.date.slice(0, 4);
      const month = this.$route.params.date.slice(4, 6);
      const date = this.$route.params.date.slice(6, 8);
      const time = new Date(`${year}-${month}-${date}`);
      const hr = Number(this.taskNewTime.slice(0, 2)) * 60 * 60 * 1000;
      const min = Number(this.taskNewTime.slice(3, 5)) * 60 * 1000;
      const finalTime = time.getTime() + hr + min - 28800000;
      this.$set(this.currentUnbookTask, 'start', finalTime);
      let duration = await 0;
      await this.currentUnbookTask.task.forEach((ele) => {
        duration += Number(ele.planed) * 60000;
      });
      await this.$set(this.currentUnbookTask, 'end', finalTime + duration);
      // this.$set(this.currentUnbookTask, 'color', 'grey');
      // this.$set(this.currentUnbookTask, 'category', 'A路線');
      this.$set(this.currentUnbookTask, 'category', { name: 'A路線' });
      this.$set(this.currentUnbookTask, 'timed', true);
      this.unbookDialog = false;
      await this.$set(this, 'newTask', this.currentUnbookTask);
      this.updateEvent();
    },
    closeUnbookDialog() {
      this.unbookDialog = false;
      this.newTask = {
        region: '',
        task: [],
        member: [],
        start: '',
        end: '',
        location: '',
        category: {},
        traffic: [],
      };
    },
    taskBlur() {
      console.log('hahahaha', this.newTask);
    },
    tabEvent() {
      this.snackbar = true;
    },
    async closeSelectDialog() {
      this.newTask.totalPlaned = 0;
      this.newTask.totalScore = 0;
      console.log('closeseeeee', this.newTask);
      if (this.taskKind === 'task') {
        this.tempArr = [];
      } else {
        this.tempTrafficArr = [];
      }
      await this.newTask.task.forEach((ele) => {
        this.newTask.totalPlaned += Number(ele.planed);
        this.newTask.totalScore += Number(ele.score);
      });
      const newTime = this.newTask.start + (this.newTask.totalPlaned * 60000);
      await this.$set(this.newTask, 'end', newTime);
      await this.updateEvent();
      // this.newTask.end = this.newTask.start + (this.newTask.totalPlaned * 60000);
      // this.selectDialog = false;
      this.$set(this, 'selectDialog', false);
    },
    // getCurrentTime() {
    //   return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    // },
    // scrollToTime() {
    //   const time = this.getCurrentTime();
    //   const first = Math.max(0, time - (time % 30) - 30);
    //   console.log(this.cal, '|||||\\\\||||', first);
    //   this.cal.scrollToTime({ time: 630 });
    // },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    async prev() {
      await this.$refs.calendar.prev();
      console.log(this.focus, 'LLLLL');
    },
    async next() {
      console.log(this.focus, 'NNNNNNNN');
    },
    async taskChoose(e, kind) {
      const chooseArr = [];
      this.taskKind = kind;
      console.log('fire tttt', e);
      e.target.blur();
      this.selectDialog = true;
      if (this.taskKind === 'task') {
        await this.newTask.task.forEach((ele) => {
          chooseArr.push(ele.time);
        });
        await this.$set(this, 'abilityArray', chooseArr);
      } else {
        this.newTask.traffic.forEach((ele) => {
          chooseArr.push(ele.time);
        });
        await this.$set(this, 'trafficArray', chooseArr);
      }
    },
    async taskSelect() {
      this.tempArr = [];
      this.tempTrafficArr = [];
      if (this.taskKind === 'task') {
        await this.abilityArray.forEach((ele) => {
          this.loopSelect(this.$store.state.taskCategories, ele);
        });
        await this.tempArr.filter((item, index) => this.tempArr.indexOf(item.id) === index);
        await this.$set(this.newTask, 'task', this.tempArr);
        console.log(this.tempArr, 'task*******');
      } else {
        await this.trafficArray.forEach((ele) => {
          this.loopSelect(this.$store.state.trafficCategories, ele);
        });
        await this.tempTrafficArr
          .filter((item, index) => this.tempTrafficArr.indexOf(item.id) === index);
        await this.$set(this.newTask, 'traffic', this.tempTrafficArr);
        console.log(this.tempTrafficArr, 'traffic*******');
      }
    },
    loopSelect(tree, id) {
      console.log(id);
      tree.forEach((ele) => {
        if (ele.id === id) {
          if (this.taskKind === 'task') {
            const some = this.tempArr.some((el) => el.id === ele.id);
            if (!some) {
              this.tempArr.push(ele);
            }
          } else {
            this.tempTrafficArr.push(ele);
          }
        }
        if (ele.children.length > 0) {
          this.loopSelect(ele.children, id);
        }
      });
    },
    async moveEventToDate() {
      const year = this.$route.params.date.slice(0, 4);
      const month = this.$route.params.date.slice(4, 6);
      const date = this.$route.params.date.slice(6, 8);
      this.changeDate = false;
      const time = new Date(`${year}-${month}-${date}`);
      const wannaTime = new Date(this.pickDate);
      const diff = wannaTime.getTime() - time.getTime();
      this.newTask.start += await diff;
      this.newTask.end += await diff;
      this.updateEvent();
    },
    bookUnbook(task) {
      console.log(task, 'FGGGGGGG');
      this.$set(this, 'currentUnbookTask', task);
      // this.currentUnbookTask = task;
    },
    async readTaskFromTasks(array) {
      const arr = [];
      array.forEach((ele) => {
        if (ele.children.length > 0) {
          this.readTaskFromTasks(ele.children);
        } else {
          this.$store.state.abilities.push(ele);
        }
      });
      // await console.log(this.abilities, '++++++++');
      return arr;
    },
    openUnbook() {
      this.unbookDialog = true;
      // this.getUnbook();
    },
    hide() {
      this.calculateWidth = 0;
      this.calenderWidth = 12;
    },
    show() {
      this.calculateWidth = 4;
      this.calenderWidth = 8;
    },
    async calculate() {
      console.log(this.todayWorkers, 'PPPPPPPP');
      await this.todayWorkers.forEach((work) => {
        this.$set(work, 'planed', 0);
      });
      await this.events.forEach((ele) => {
        if (ele.member) {
          ele.member.forEach(async (el) => {
            const find = this.todayWorkers.find((ele) => ele.name === el.name);
            if (find) {
              console.log(ele, 'GGGGGGGGGG');
              ele.task.forEach((ta) => {
                find.planed += Number(ta.planed);
                // this.$set(this, 'planedSum', 999);
                this.planedSum += Number((Number(ta.planed) / 60).toFixed(1));
              });
              if (ele.arrived && ele.finished) {
                const diff = (ele.finished.time - ele.arrived.time);
                find.finished += diff / 60000;
              }
            }
          });
        }
      });
    },
    async getTodayEvent() {
      console.log('fire getttttttttttttttttt');
      // let that = this
      // const todayTime = new Date(this.$store.state.currentDate);
      const calYear = this.$route.params.date.slice(0, 4);
      const calMonth = this.$route.params.date.slice(4, 6);
      const calDay = this.$route.params.date.slice(6, 8);
      // const todayTime = new Date(this.$refs.calendar.times.today.date);
      const todayTime = new Date(`${calYear}-${calMonth}-${calDay}`);
      await db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('start', '>=', todayTime.getTime())
        .where('start', '<', todayTime.getTime() + 86400000)
        // .where('start', '>=', todayTime.getTime() - 28800000)
        // .where('start', '<', todayTime.getTime() + 57600000)
        .where('actived', '==', true)
        .where('region', '==', this.$store.state.region)
        .onSnapshot(async (snapshot) => {
          this.events = await [];
          this.totalPlan = await 0;
          this.totalDuration = await 0;
          await snapshot.forEach((change) => {
            change.data().task.forEach((ta) => {
              this.totalPlan += Number(ta.planed) / 60;
            });
            change.data().member.forEach((ele) => {
              // const find = this.todayWorkers.find((el) => el.name === ele.name);
              change.data().task.forEach((e) => {
                const duration = Number(change.data().duration);
                console.log('QQQQQQQQQQ', ele, e);
                if (change.data().duration !== undefined) {
                  this.totalDuration += Number((duration / 3600000).toFixed(1));
                }
              });
            });
            const checkDouble = this.events.find((ele) => ele.id === change.data().id);
            if (checkDouble === undefined) {
              this.events.push(change.data());
            }
            // 如果有師傅，找出對應任務
            console.log(change.data().category, 'KLKLKKKKKKKKKK', this.events);
          });
          await console.log(this.todayWorkers, '||||||||||');
          await this.calculate();
        });
    },
    setDist(city) {
      const found = this.taiwan.find((ele) => ele.name === city);
      this.$set(this, 'districts', found.districts);
    },
    async getDetail() {
      if (this.$store.state.region !== this.areas[this.tab].name) {
        this.$store.state.region = this.areas[this.tab].name;
      }
      this.getLines();
      await this.getWorkers(this.$store.state.region);
      await console.log('get detail');
      await this.getTodayEvent();
    },
    async workerCal(task) {
      const availableWorkers = [];
      await this.events.forEach((ele) => {
        if (ele.member !== undefined) {
          ele.member.forEach((el) => {
            availableWorkers.push(el.name);
          });
        }
      });
      const result = await availableWorkers
        .filter((element, index, arr) => arr.indexOf(element) === index);
      await console.log(task, 'GGGGGGGGG');
      // let totalTime = await 0;
      if (task.member[0] === undefined) {
        await this.$set(task, 'color', 'grey');
      } else {
        await this.$set(task, 'color', task.member[0].color);
      }
      const newTask = await task;
      const calYear = this.$route.params.date.slice(0, 4);
      const calMonth = this.$route.params.date.slice(4, 6);
      const calDay = this.$route.params.date.slice(6, 8);
      if (task.id === undefined) {
        console.log('this is the new task');
      } else {
        api.workerUpdate({
          year: calYear,
          month: calMonth,
          date: calDay,
          availableWorkers: result,
          region: this.$store.state.region,
          task: newTask,
        })
          .then(async ({ data }) => {
            if (data.success) {
              console.log(data, 'worker updateeeee');
            } else {
              console.log('err:', data);
            }
          });
      }
    },
    getWorkers(area) {
      const that = this;
      api.getWorkers({ region: area })
        .then(async ({ data }) => {
          if (data.success) {
            this.$set(that, 'todayWorkers', data.workers);
            console.log(data, 'get workers');
          } else {
            console.log('err:', data);
          }
        })
        .then(() => {
          this.todayWorkers.forEach((ele) => {
            console.log('lalala', ele);
            this.$set(ele, 'planed', 0);
            this.$set(ele, 'finished', 0);
          });
        });
    },
    getRegionEvents(area) {
      // const that = this;
      console.log('fire get event');
      api.getEvents({ region: area })
        .then(async ({ data }) => {
          if (data.success) {
            data.events.forEach((ele) => {
              this.events.push(ele);
            });
            console.log(data, 'get events');
          } else {
            console.log('err:', data);
          }
        });
    },
    today() {
      const time = new Date(this.focus);
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const weekDay = time.getDay();
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

      return `${year}年${month}月${day}日 ${days[weekDay]}`;
    },
    editEvent(event) {
      console.log(event, 'sjowwwwwwww');
    },
    startDrag(event) {
      console.log('start draggggggggg', event.event, event.timed);
      if (event.event && event.timed) {
        console.log('deeeeeee', event.event);
        this.newTask = event.event;
        this.dragEvent = event.event;
        this.dragTime = null;
        this.extendOriginal = null;
        // testing
        this.beforeDragStart = JSON.parse(JSON.stringify(event.event.start));
        this.beforeDragEnd = JSON.parse(JSON.stringify(event.event.end));
        this.beforeDragCategory = JSON.parse(JSON.stringify(event.event.category));
      }
    },
    async startEdit() {
      this.dialog = await true;
      this.edit = true;
      // await this.$set(this.newTask, 'region', this.$store.state.region);
      // await this.$set(this.newTask, 'start', this.toTime(tms));
      // await this.readTaskFromTasks(this.$store.state.taskCategories);
      // await console.log('start edddddddd', this.newTask);
      await this.$set(this.newTask, 'region', this.$store.state.region);
    },
    async getUnbook() {
      const calYear = this.$route.params.date.slice(0, 4);
      const calMonth = this.$route.params.date.slice(4, 6);
      const calDay = this.$route.params.date.slice(6, 8);
      const todayTime = new Date(`${calYear}-${calMonth}-${calDay}`);
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('date', '>=', todayTime.getTime())
        .where('date', '<', todayTime.getTime() + 86400000)
        .where('actived', '==', true)
        .onSnapshot(async (snapshot) => {
          console.log(snapshot, 'KKKKKKKLLLLLLL');
          this.waiting = await [];
          this.areas = [
            {
              name: '北區',
              notify: 0,
            }, {
              name: '桃竹',
              notify: 0,
            }, {
              name: '中區',
              notify: 0,
            }, {
              name: '南區',
              notify: 0,
            }, {
              name: '淨水',
              notify: 0,
            }, {
              name: '大水',
              notify: 0,
            },
          ];
          await snapshot.forEach((change) => {
            this.waiting.push(change.data());
            const found = this.areas.find((ele) => ele.name === change.data().region);
            found.notify += 1;
          });
        });
      // const calYear = this.$route.params.date.slice(0, 4);
      // const calMonth = this.$route.params.date.slice(4, 6);
      // const calDay = this.$route.params.date.slice(6, 8);
      // const todayTime = new Date(`${calYear}-${calMonth}-${calDay}`);
      // api.getUnbook({ time: todayTime.getTime() - 28800000 })
      //   .then(async ({ data }) => {
      //     if (data.success) {
      //       this.$set(this, 'waiting', data.unbooks);
      //       // this.waiting = data.unbooks;
      //       console.log(data, 'get unnnbbbb');
      //     } else {
      //       console.log('err:', data);
      //     }
      //   });
    },
    async unbook() {
      console.log(this.newTask, '>>>>');
      this.finishEdit();
      const calYear = this.$route.params.date.slice(0, 4);
      const calMonth = this.$route.params.date.slice(4, 6);
      const calDay = this.$route.params.date.slice(6, 8);
      const todayTime = new Date(`${calYear}-${calMonth}-${calDay}`);
      this.edit = await true;
      this.dialog = await true;
      await this.$set(this.newTask, 'region', this.$store.state.region);
      await this.$set(this.newTask, 'date', todayTime.getTime() - 28800000);
    },
    async unbookedCreate() {
      let totalPlaned = 0;
      let totalScore = 0;
      if (this.newTask.task.length > 0 && this.newTask.task !== undefined) {
        console.log('unbbbbbb', this.newTask);
        this.newTask.task.forEach((ele) => {
          totalPlaned += Number(ele.planed);
          totalScore += Number(ele.score);
        });
        if (this.newTask.member.length > 0) {
          this.newTask.member.forEach((ele) => {
            this.$set(this.newTask, 'color', ele.color);
            console.log(ele, '?????');
          });
        } else {
          this.newTask.color = 'grey';
        }
        if (this.newTask.traffic.length > 0) {
          this.newTask.traffic.forEach((ele) => {
            totalPlaned += Number(ele.planed);
            totalScore += Number(ele.score);
          });
        }
        const newData = {
          category: this.newTask.category,
          color: this.newTask.color,
          date: this.newTask.date,
          sheetId: this.newTask.sheetId,
          clientName: this.newTask.clientName,
          clientId: this.newTask.clientId,
          clientTel: this.newTask.clientTel,
          start: '',
          end: '',
          location: this.newTask.location,
          dist: this.newTask.dist,
          address: this.newTask.address,
          member: this.newTask.member,
          name: this.newTask.name,
          region: this.newTask.region,
          task: this.newTask.task,
          timed: this.newTask.timed,
          traffic: this.newTask.traffic,
          history: [{
            time: null,
            name: this.$store.state.userInfo.name,
            process: '創造任務',
          }],
          totalPlaned,
          totalScore,
          actived: true,
        };
        await api.createEvent({
          length: this.events.length,
          data: newData,
        })
          .then(async ({ data }) => {
            if (data.success) {
              console.log('lalala');
              // this.getTodayEvent();
              // console.log(data, 'eventtttttttt', obj);
              await this.finishEdit();
              this.edit = await false;
            } else {
              console.log('err:', data);
            }
          });
        // await api.unbookCreate(this.newTask)
        //   .then(async ({ data }) => {
        //     if (data.success) {
        //       // await this.events.push(obj);
        //       console.log(data, 'ubbbbbbbbbbb');
        //       this.newTask = {
        //         region: '',
        //         task: [],
        //         member: [],
        //         start: '',
        //         end: '',
        //         location: '',
        //         traffic: [],
        //       };
        //       this.edit = false;
        //       this.dialog = false;
        //     } else {
        //       console.log('err:', data);
        //     }
        //   });
      } else {
        alert('請輸入任務內容');
        console.log('required!');
      }
    },
    async readyToCreate() {
      let totalPlaned = 0;
      let totalScore = 0;
      console.log('fire createeeeeeee', this.newTask);
      if (this.newTask.task.length > 0 && this.newTask.task !== undefined) {
        console.log('create okokok', this.createStart);
        let totalTime = await 0;
        const tms = this.gonnaCreateEvent;
        // this.createStart = await new Date(`${tms.date} ${tms.time}:00`);
        await this.newTask.task.forEach((ele) => {
          totalTime += Number(ele.planed);
        });
        await this.newTask.traffic.forEach((ele) => {
          totalTime += Number(ele.planed);
        });
        await console.log(tms, '()()()(((((((((())))))))))', this.newTask);
        const endTime = await this.createStart + (totalTime * 60000);
        // const endTime = await this.createStart.getTime() + (totalTime * 60000);
        this.createEvent = await {
          name: `Event #${this.events.length}`,
          color: 'grey',
          start: this.createStart,
          end: new Date(endTime),
          timed: true,
          category: tms.category,
        };
        this.newTask.task.forEach((ele) => {
          totalPlaned += Number(ele.planed);
          totalScore += Number(ele.score);
        });
        if (this.newTask.traffic.length > 0) {
          this.newTask.traffic.forEach((ele) => {
            totalPlaned += Number(ele.planed);
            totalScore += Number(ele.score);
          });
        }
        // await this.$set(this.newTask, 'start', this.toTime(tms));
        const obj = await Object.assign(this.newTask, this.createEvent);
        const saveStartTime = new Date(this.newTask.start);
        const saveEndTime = new Date(this.newTask.end);
        if (this.newTask.member.length > 0) {
          this.newTask.member.forEach((ele) => {
            this.$set(this.newTask, 'color', ele.color);
            console.log(ele, '?????');
          });
        }
        // await api.createEvent({
        //   category: this.newTask.category,
        //   color: this.newTask.color,
        //   dist: this.newTask.dist,
        //   start: saveStartTime.getTime(),
        //   end: saveEndTime.getTime(),
        //   location: this.newTask.location,
        //   member: this.newTask.member,
        //   name: this.newTask.name,
        //   region: this.newTask.region,
        //   task: this.newTask.task,
        //   timed: this.newTask.timed,
        //   traffic: this.newTask.traffic,
        //   actived: true,
        // })
        const newData = {
          category: this.newTask.category,
          color: this.newTask.color,
          sheetId: this.newTask.sheetId,
          clientName: this.newTask.clientName,
          clientId: this.newTask.clientId,
          clientTel: this.newTask.clientTel,
          start: saveStartTime.getTime(),
          end: saveEndTime.getTime(),
          location: this.newTask.location,
          dist: this.newTask.dist,
          address: this.newTask.address,
          member: this.newTask.member,
          name: this.newTask.name,
          region: this.newTask.region,
          task: this.newTask.task,
          brief: this.newTask.brief,
          timed: this.newTask.timed,
          traffic: this.newTask.traffic,
          history: [{
            time: null,
            name: this.$store.state.userInfo.name,
            process: '創建任務',
          }],
          totalPlaned,
          totalScore,
          actived: true,
        };
        console.log(newData, '****************');
        await api.createEvent({
          length: this.events.length,
          areas: this.areas,
          data: newData,
        })
          .then(async ({ data }) => {
            if (data.success) {
              console.log('lalala');
              // this.getTodayEvent();
              console.log(data, 'eventtttttttt', obj);
              await this.finishEdit();
              this.edit = await false;
            } else {
              console.log('err:', data);
            }
          });
        // console.log(totalPlaned, totalScore, newData, obj);
      } else {
        alert('請輸入任務內容');
        this.edit = false;
        console.log('required!');
      }
    },
    copyEvent(event) {
      console.log(event, '?????', this.newTask);
      const newData = {
        category: this.newTask.category,
        color: this.newTask.color,
        sheetId: this.newTask.sheetId,
        clientName: this.newTask.clientName,
        clientId: this.newTask.clientId,
        clientTel: this.newTask.clientTel,
        start: this.newTask.start,
        end: this.newTask.end,
        location: this.newTask.location,
        dist: this.newTask.dist,
        address: this.newTask.address,
        member: this.newTask.member,
        name: this.newTask.name,
        region: this.newTask.region,
        task: this.newTask.task,
        brief: this.newTask.brief,
        timed: this.newTask.timed,
        traffic: this.newTask.traffic,
        history: [{
          time: null,
          name: this.$store.state.userInfo.name,
          process: '創建任務',
        }],
        totalPlaned: this.newTask.totalPlaned,
        totalScore: this.newTask.totalScore,
        actived: true,
      };
      api.createEvent({
        length: this.events.length,
        areas: this.areas,
        data: newData,
      })
        .then(async ({ data }) => {
          if (data.success) {
            console.log('lalala');
            // this.getTodayEvent();
            console.log(data, 'eventtttttttt');
            await this.finishEdit();
            this.edit = await false;
          } else {
            console.log('err:', data);
          }
        });
      // this.readyToCreate();
    },
    removeEvent(event) {
      console.log(event, '?????');
      api.removeEvent({
        region: event.region,
        start: event.start,
        id: event.id,
      })
        .then(async ({ data }) => {
          if (data.success) {
            // const found = this.events.findIndex((ele) => ele.id === event.id);
            // this.events.splice(found, 1);
            this.dialog = false;
            this.edit = false;
            this.currentUnbookTask = {};
            console.log(this.events, 'remove events');
            await this.finishEdit();
          } else {
            console.log('err:', data);
          }
        });
    },
    async startTime(tms) {
      // await this.finishEdit();
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        // const start = this.dragEvent.start;
        const { start } = this.dragEvent;
        this.dragTime = mouse - start;
        // console.log('not createeeeeeeee', start, mouse);
      } else {
        console.log(tms, 'OOOOOOOOOOOOO', this.roundTime(mouse), mouse);
        this.ableToCreate = true;
        this.createStart = await this.roundTime(mouse);
        // this.createStart = await new Date(`${tms.date} ${tms.time}:00`);
        // const endTime = await this.createStart.getTime() + 3600000;
        // await this.$set(this.newTask, 'start', mouse);
        // await this.$set(this.newTask, 'end', endTime);
        await this.$set(this.newTask, 'start', this.roundTime(mouse));
        await this.$set(this.newTask, 'end', this.roundTime(mouse) + 3600000);
        this.$set(this, 'gonnaCreateEvent', tms);
        // this.createStart = await new Date(`${tms.date} ${tms.time}:00`);
        // const endTime = await this.createStart.getTime() + 3600000;
        // this.createEvent = await {
        //   name: `Event #${this.events.length}`,
        //   color: this.rndElement(this.colors),
        //   start: this.createStart,
        //   end: new Date(endTime),
        //   timed: true,
        //   category: tms.category,
        // };
        // await this.$set(this.newTask, 'region', this.$store.state.region);
        // await this.$set(this.newTask, 'start', this.toTime(tms));
        // const obj = await Object.assign(this.newTask, this.createEvent);
        // this.events.push(obj);
        const check = this.$store.state.userInfo.position !== 'viewer';
        if (check) {
          this.startEdit();
        }
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);
      // console.log(mouse, '_+_+_+_+_+_+_++');
      if (this.dragEvent && this.dragTime !== null) {
        const { start } = this.dragEvent;
        const { end } = this.dragEvent;
        // const start = this.dragEvent.start;
        // const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = this.roundTime(mouse - this.dragTime);
        // const newStartTime = mouse - this.dragTime;
        // const newStart = newStartTime;
        const newCategory = tms.category;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
        this.dragEvent.category = newCategory;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    // cancelDrag() {
    //   this.dragEvent.start = this.beforeDragStart;
    //   this.dragEvent.end = this.beforeDragEnd;
    //   this.dragEvent.category = this.beforeDragCategory;
    // },
    async saveDrag() {
      this.dragDialog = false;
      console.log(this.newTask, '{}{}{}{}{}{}{}{}{}');
      // if (this.edit === true) {
      //   await this.updateEvent();
      // }
      await this.updateEvent();
      // await this.startEdit();
      // this.dragEvent = null;
      // this.createEvent = null;
      // this.createStart = null;
      // this.dragDialog = false;
      // this.$set(this, 'newTask', {});
      this.finishEdit();
    },
    async backDrag() {
      this.dragEvent.start = await this.beforeDragStart;
      this.dragEvent.end = await this.beforeDragEnd;
      this.dragEvent.category = await this.beforeDragCategory;
      this.dragDialog = await false;
      // await this.finishEdit();
      this.dragEvent = null;
    },
    async endDrag() {
      this.dragTime = await null;
      const timeCheck = this.beforeDragStart !== this.dragEvent.start;
      const categoryCheck = this.beforeDragCategory.name !== this.dragEvent.category.name;
      console.log(timeCheck || categoryCheck, 'MM<<<<MMMMM<<<<<<');
      if (timeCheck || categoryCheck) {
        // this.dragTime = null;
        // this.dragEvent = null;
        if (this.$store.state.userInfo.position === 'viewer') {
          this.backDrag();
        } else {
          this.dragDialog = true;
        }
      }
    },
    outsideEdit() {
      if (!this.newTask.id) {
        console.log(this.newTask, 'readyyyyyyyyy');
        this.readyToCreate();
      } else {
        console.log(this.newTask, 'cancellllllll');
        this.finishEdit();
      }
    },
    cancelEdit() {
      this.finishEdit();
    },
    cancelDrag() {
      this.dragDialog = false;
      console.log('start ddddddccccccc');
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.finishEdit();
      // this.createEvent = null;
      // this.createStart = null;
      // this.dragTime = null;
      // this.dragEvent = null;
    },
    roundTime(time, down = true) {
      console.log(time, 'lalala');
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },
    finishEdit() {
      console.log('fire finissssssss', this.newTask, this.tempArr);
      this.edit = false;
      this.dialog = false;
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.abilityArray = [];
      this.$set(this, 'newTask', {
        region: '',
        task: [],
        member: [],
        start: '',
        end: '',
        location: '',
        category: {},
        traffic: [],
      });
      // this.extendOriginal = null;
    },
    // deleteEdit(event) {
    //   console.log(event, '?????');
    // },
    test(some) {
      console.log(some, 'MMMMMM');
    },
    addLine() {
      // this.lineDialog = true;
      this.newCategories.push({
        name: `${this.alphabet[this.newCategories.length]}路線`,
        note: '',
      });
      // this.categories.push(`${this.alphabet[this.categories.length]}路線`);
    },
    reduceLine() {
      // this.lineDialog = true;
      this.newCategories.pop();
      // this.categories.push(`${this.alphabet[this.categories.length]}路線`);
    },
    doNothing() {

    },
    getEventColor(event) {
      // console.log('colorrrrrrr', event);
      // const rgb = parseInt(event.color.substring(1), 16);
      // const r = 123;
      // const g = 123;
      // const b = 123;
      // const r = (rgb >> 16) && 0xFF
      // const g = (rgb >> 8) && 0xFF
      // const b = (rgb >> 0) && 0xFF
      let color = '';
      if (event === this.dragEvent) {
        color = 'rgba(123, 123, 123, 0.7)';
      } else if (event === this.createEvent) {
        color = 'rgba(123, 123, 123, 0.7)';
      } else {
        color = event.color;
      }
      return color;
      // return event === this.dragEvent
      //   ? `rgba(${r}, ${g}, ${b}, 0.7)`
      //   : event === this.createEvent
      //     ? `rgba(${r}, ${g}, ${b}, 0.7)`
      //     : event.color;
      // return event.color;
    },
    setToday() {
      this.focus = '';
    },
    getEvents(event) {
      console.log('get event', event.event.id, this.dragDialog);
      if (!this.dragDialog) {
        const docRef = db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g');
        docRef.get().then((doc) => {
          if (doc.exists) {
            // this.$set(this.$store.state, 'abilities', doc.data().tasks);
            this.readTaskFromTasks(doc.data().tasks);
            console.log('event document data:');
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
          }
        }).catch((error) => {
          console.log('Error getting document:', error);
        });
        this.dialog = true;
        this.$set(this, 'newTask', event.event);
        this.ableToCreate = false;
      }
    },
    async updateEvent() {
      let totalPlaned = 0;
      let totalScore = 0;
      console.log('fire update', this.newTask);
      if (this.newTask.id) {
        await this.newTask.task.forEach((ele) => {
          totalPlaned += Number(ele.planed);
          totalScore += Number(ele.score);
        });
        if (this.newTask.traffic.length > 0) {
          await this.newTask.traffic.forEach((ele) => {
            totalPlaned += Number(ele.planed);
            totalScore += Number(ele.score);
          });
        }
        this.uploading = true;
        const checkPlaned = this.newTask.totalPlaned !== totalPlaned;
        const checkScore = this.newTask.totalScore !== totalScore;
        if (checkPlaned || checkScore) {
          this.newTask.totalPlaned = await totalPlaned;
          this.newTask.totalScore = await totalScore;
          this.newTask.end = this.newTask.start + Number(totalPlaned * 60000);
        }
        console.log(this.newTask, '{+{+{+{+}}}}');
        api.updateEvent({
          editor: this.$store.state.userInfo.name,
          data: this.newTask,
        })
          .then(async ({ data }) => {
            if (data.success) {
              this.uploading = false;
              console.log(this.events, 'update events');
            } else {
              console.log('err:', data);
            }
          });
      }
      // this.selectDialog = false;
      // document.activeElement.blur();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
};
</script>
<style scoped lang="scss">
.bookCard{
  border-bottom: 1px rgba(77, 188, 184, .3) solid;
  border-radius: 5px;
  padding: 0px 1px;
}
.arrow {
  font-size:100px;
  margin: -30px 0px -30px auto;
  margin-top:-30px;
  margin-bottom:-30px;
  opacity:0.4;
  cursor:pointer;
  z-index:999
}
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-current-time {
  height: 3px;
  background-color: rgb(77, 188, 184);
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: rgb(77, 188, 184);
    width: 14px;
    height: 14px;
    z-index: 9;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
