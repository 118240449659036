<template>
  <div class="mobile">
    <v-row style="margin-top:20px;">
      <v-col cols="12">
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            interval-height="60"
            v-model="focus"
            color="#4DBCB8"
            type="category"
            category-show-all
            :events="events"
            :categories="categories"
            @click:event="action"
          >
            <template v-slot:event="{ event, eventParsed}">
              <div style="padding:5px;">
                <span v-if="event.finished">
                  <v-icon size="18" color="white">
                    mdi-check-all
                  </v-icon>
                </span>
                <span v-else-if="event.arrived">
                  <v-icon color="white">
                    mdi-check
                  </v-icon>
                </span>
                <span v-else>
                </span>
                <span>
                  (
                  <span v-if="event.images">{{event.images.length}}</span>
                  <span v-else>0</span>
                  / {{ totalImages(event.task) }} )
                </span>
                <span>
                  <span v-for="task in event.task" :key="task.name">{{task.name}},</span>
                </span>
                <br>
                <span>{{eventParsed.start.time}}-{{eventParsed.end.time}}</span>
                <br>
                <span v-for="worker in event.member" :key="worker.name">{{worker.name}}</span>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="this.newTask.id !== undefined" style="box-shadow:none;" elevation="0">
        <v-row>
          <v-spacer />
          <v-col cols="2">
            <v-icon
              style="font-size:40px;float:right;"
              @click="dialog = false"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row v-for="taskDetail in taskHead" :key="taskDetail.label">
          <v-spacer />
          <v-col cols="1">
            <v-icon style="color:#4DBCB8">
              {{taskDetail.icon}}
            </v-icon>
          </v-col>
          <v-col cols="3">
            {{ taskDetail.label }}
          </v-col>
          <v-col cols="7">
            <p v-if="taskDetail.value === 'member'||taskDetail.value === 'task'">
              {{ newTask[taskDetail.value][0].name }}
            </p>
            <p v-else-if="taskDetail.value === 'start'||taskDetail.value === 'end'">
              {{ (new Date(newTask[taskDetail.value])).getFullYear() }}/
              {{ (new Date(newTask[taskDetail.value])).getMonth() + 1 }}/
              {{ (new Date(newTask[taskDetail.value])).getDate() }}
              {{ (new Date(newTask[taskDetail.value])).getHours() }}:
              {{ ((new Date(newTask[taskDetail.value])).getMinutes()).toString().padStart(2, '0') }}
              ({{ taiwanWeek[(new Date(newTask[taskDetail.value])).getDay()] }})
              <!-- {{ new Date(newTask[taskDetail.value]) }} -->
            </p>
            <p v-else-if="taskDetail.value === 'traffic'">
              <span v-if="newTask.traffic.length > 0">
                {{ newTask[taskDetail.value][0].score }}
              </span>
            </p>
            <p v-else-if="taskDetail.value === 'location'">
              {{ newTask.location }}{{ newTask.dist }}{{ newTask.address }}
            </p>
            <p v-else-if="taskDetail.value === 'region'">
              {{ newTask.region }} ({{ newTask.sheetId }})
            </p>
            <p v-else>{{ newTask[taskDetail.value] }}</p>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <div v-for="ta in newTask.task" :key="ta.name">
              拍照內容({{ ta.name }})：
              <span v-for="(img,index) in ta.images" :key="img">
                {{ img }}
                <span v-if="index !== ta.images.length - 1">、</span>
              </span>
              (
              <span v-if="newTask.images !== undefined">{{newTask.images.length}}</span>
              <span v-else>0</span>
              /
              <span v-if="ta.images !== undefined">{{ta.images.length}}</span>
              <span v-else>0</span>
              )
            </div>
            <v-file-input
              v-model="selectedFiles"
              accept="image/*"
              prepend-icon="mdi-camera-plus"
              :loading="loading"
              :disabled="loading"
              multiple
              @change="newUploadMutipleFile"
            ></v-file-input>
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <!-- <v-file-input
              v-model="file"
              accept="image/*"
              prepend-icon="mdi-camera-plus"
              :loading="loading"
              :disabled="loading"
              @change="uploadFile"
            ></v-file-input> -->
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <v-row>
              <v-col cols="4" v-for="(image, index) in newTask.images" :key="image">
                <v-menu
                  v-model="menuOpen[index]"
                  absolute
                  offset-y
                  style="width: 100%"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      v-show="index < loadedCount"
                      style="width:100%;cursor:pointer;"
                      aspect-ratio="1"
                      :lazy-src="getImageSrc(image)"
                      :src="getImageSrc(image)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-img>
                  </template>
                  <v-list>
                    <v-list-item @click="goPic(getImageSrc(image))">
                      <v-list-item-icon>
                        <v-icon color="#4DBCB8">mdi-link-box-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        展開圖片
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removePic(image)">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-trash-can</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        刪除圖片
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="newTask.images !== undefined">
              <v-spacer />
              <v-col cols="4" style="text-align:center;">
                <v-btn
                  outlined
                  color="#4DBCB8"
                  v-if="loadedCount < newTask.images.length"
                  @click="loadMore">
                  載入更多
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
            <!-- <v-overlay :value="imgLoading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay> -->
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <div v-if="newTask.arrived !== undefined">
              到達時間：{{new Date(newTask.arrived.time)}}
              <v-icon @click="goMap(newTask.arrived.lat,newTask.arrived.lon)">
                mdi-map-marker
              </v-icon>
            </div>
            <div v-if="newTask.finished !== undefined">
              完成時間：{{new Date(newTask.finished.time)}}
              <v-icon @click="goMap(newTask.finished.lat,newTask.finished.lon)">
                mdi-map-marker
              </v-icon>
            </div>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <v-textarea
              v-if="newTask.comments"
              label="備註"
              color="#4DBCB8"
              outlined
              background-color="transparent"
              clearable
              auto-grow
              v-model="newTask.comments"
            ></v-textarea>
            <v-textarea
              v-else
              label="備註"
              color="#4DBCB8"
              outlined
              background-color="transparent"
              clearable
              auto-grow
              v-model="comments"
            ></v-textarea>
          </v-col>
          <v-spacer />
        </v-row>
        <v-btn
          class="reportButton"
          height="80"
          :color="btnColor"
          style="padding:60px auto;margin-top:20px;"
          @click="doubleCheck"
          :disabled="newTask.finished !== undefined"
        >
          <span
            v-if="newTask.arrived === undefined"
            style="color:white;margin:50px;"
          >
            回報抵達
          </span>
          <span
            v-else
            style="color:white;margin:50px;"
          >
            回報完成
          </span>
        </v-btn>
        <!-- <v-bottom-navigation
          v-model="value"
          color="white"
          style="background-color:#4DBCB8;"
        >
          <span style="margin-top:4px;font-size:30px;">回報抵達</span>
        </v-bottom-navigation> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="doubleDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        style="box-shadow:none;text-align:center;font-size:30px;"
        elevation="0"
      >
        <v-row>
          <v-spacer />
          <v-col cols="11" style="margin:160px auto 40px auto;">
            <span v-if="newTask.arrived === undefined">是否要回報已抵達？</span>
            <span v-else>是否要回報已完成？</span>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <v-btn
              v-if="newTask.arrived === undefined"
              height="100"
              color="#4DBCB8"
              dark
              style="width:100%;font-size:30px;"
              :loading="loadingGeo"
              @click="report('arrived')"
            >
              是，已抵達
              <template v-slot:loader>
                <span>讀取定位中...</span>
                <v-progress-circular
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
            <v-btn
              v-else
              height="100"
              color="#4DBCB8"
              dark
              style="width:100%;font-size:30px;"
              :loading="loadingGeo"
              @click="report('finished')"
            >
              是，已完成
              <template v-slot:loader>
                <span>讀取定位中...</span>
                <v-progress-circular
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <v-btn
              height="100"
              color="#4DBCB8"
              outlined
              style="width:100%;font-size:30px;"
              @click="doubleDialog = false"
            >
              否，稍後回報
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row v-if="newTask.arrived !== undefined">
          <v-spacer />
          <v-col cols="11">
            <v-btn
              height="100"
              color="orange"
              dark
              style="width:100%;font-size:30px;"
              :loading="loadingGeo"
              :disabled="loadingGeo"
              @click="report('delay')"
            >
              未完成
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import { db } from '../db';
// import { db, storage } from '../db';
import api from '../api/mobile';

export default {
  name: 'Mobile',
  components: {
  },
  data() {
    return {
      overlay: false,
      menuOpen: [],
      loadedCount: 6, // 控制已加載圖片數量
      step: 3, // 控制每次加載的圖片數量
      selectedFiles: [],
      comments: '',
      loadingGeo: true,
      taiwanWeek: ['日', '一', '二', '三', '四', '五', '六'],
      loading: false,
      file: null,
      btnColor: '#4DBCB8',
      value: '',
      doubleDialog: false,
      dialog: false,
      categories: [],
      focus: '',
      newTask: {},
      location: {
        lat: '',
        lon: '',
      },
      events: [],
      taskHead: [
        {
          icon: 'mdi-city',
          label: '區域',
          value: 'region',
        }, {
          icon: 'mdi-clipboard-outline',
          label: '任務',
          value: 'task',
        }, {
          icon: 'mdi-file-document-edit-outline',
          label: '任務概述',
          value: 'brief',
        }, {
          icon: 'mdi-account',
          label: '人員',
          value: 'member',
        }, {
          icon: 'mdi-clock',
          label: '開始時間',
          value: 'start',
        }, {
          icon: '',
          label: '結束時間',
          value: 'end',
        }, {
          icon: 'mdi-map-marker',
          label: '地點',
          value: 'location',
        }, /* {
          icon: 'mdi-car-estate',
          label: '路程積分',
          value: 'traffic',
        }, */
      ],
    };
  },
  mounted() {
    this.$set(this, 'categories', [this.$store.state.userInfo.name]);
    this.getMobileTask();
    console.log(this.focus, '++++++');
  },
  methods: {
    totalImages(tasks) {
      return tasks.reduce((accumulator, task) => accumulator + task.images.length, 0);
    },
    loadMore() {
      // 加載更多圖片
      this.loadedCount += this.step;
      console.log(this.loadedCount, this.step, '||||||');
    },
    getImageSrc(image) {
      return `https://storage.googleapis.com/shihleicloud/${this.newTask.id}/${image}.jpg`;
    },
    goPic(url) {
      window.open(url);
    },
    removePic(thing) {
      const result = this.newTask.images.filter((ele) => ele !== thing);
      this.$set(this.newTask, 'images', result);
      console.log(thing, result, '???????');
      api.removeImage({
        id: this.newTask.id,
        image: thing,
        images: result,
      }).then(({
        data,
      }) => {
        if (data.success) {
          console.log('success');
        } else {
          console.log('fail');
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    goMap(lat, lon) {
      window.open(`https://www.google.com.tw/maps/place/${lat},${lon}`);
    },
    successCallback(position) {
      // console.log(position.coords);
      this.location.lat = position.coords.latitude;
      this.location.lon = position.coords.longitude;
      this.loadingGeo = false;
    },
    errorCallback(error) {
      console.log(error);
    },
    // uploadStorage() {
    //   console.log(this.selectedFiles);
    //   for (let i = 0; i < this.selectedFiles.length; i += 1) {
    //     const file = this.selectedFiles[i];
    //     const fileName = file.name;
    //     console.log(fileName, '??????');
    //     // 上傳到 Firebase Storage
    //     const storageRef = storage.ref()
    // .child(`shihleicloud/${this.newTask.id}/${(new Date()).getTime()}`);
    //     const uploadTask = storageRef.put(file);
    //     uploadTask.on(
    //       'state_changed',
    //       (snapshot) => {
    //         snapshot.forEach((ele) => {
    //           console.log(ele, 'SSSSSSS');
    //         });
    //         // 可以在此監控上傳進度，例如顯示一個進度條
    //       },
    //       (error) => {
    //         console.error('Upload failed:', error);
    //       },
    //       async () => {
    //         const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
    //         console.log('File uploaded:', downloadURL);
    //         // 下載連結已經取得，可以將連結保存到 Firestore 或執行其他操作
    //       },
    //     );
    //   }
    // },
    async newUploadMutipleFile() {
      this.loading = true;
      this.overlay = true;
      const batchSize = 1;
      const filesLength = Math.ceil(this.selectedFiles.length / batchSize);

      const batches = Array.from(
        { length: filesLength },
        (_, i) => this.selectedFiles.slice(i * batchSize, (i * batchSize) + batchSize),
      );

      const failedUploads = [];

      await batches.reduce((promiseChain, batch) => promiseChain.then(async () => {
        const formData = new FormData();
        formData.append('id', this.newTask.id);

        try {
          await Promise.all(
            batch.map((file) => {
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 3600,
                useWebWorker: true,
              };

              return imageCompression(file, options)
                .then((compressedImageFile) => {
                  formData.append('photos', compressedImageFile, file.name);
                });
            }),
          );

          const { data } = await api.uploadMultiple(formData);

          if (!data.success) {
            throw new Error('upload error');
          }

          console.log('img upload', this.newTask, this.events);
        } catch (error) {
          console.error('Error during batch processing:', error);
          failedUploads.push(...batch);
        }
      }), Promise.resolve());

      if (failedUploads.length === 0) {
        this.file = null;
        this.selectedFiles = [];
      }

      this.loading = false;
      this.overlay = false;

      this.$nextTick(() => {
        console.log('Loading finished');
        if (failedUploads.length > 0) {
          console.error(`Failed to upload ${failedUploads.length} files`, failedUploads);
        }
      });
    },
    async uploadMutipleFile(event) {
      this.loading = true;
      console.log(event, '()()()()()()()');
      // this.selectedFiles = Array.from(event);
      const formData = new FormData();
      formData.append('id', this.newTask.id);
      const compressImageTasks = this.selectedFiles.map(async (file) => {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 4800,
            useWebWorker: true,
          };
          const compressedImageFile = await imageCompression(file, options);
          formData.append('photos', compressedImageFile, file.name);
        } catch (error) {
          console.error('Image compression failed:', error);
        }
      });
      try {
        // Wait for all image compression tasks to finish
        await Promise.all(compressImageTasks);
        console.log(formData);
        api.uploadMultiple(formData).then(({
          data,
        }) => {
          if (data.success) {
            this.file = null;
            this.selectedFiles = [];
            // const found = this.events.find((ele) => ele.id === this.newTask.id);
            // this.$set(this, 'newTask', found);
            console.log('img upload', this.newTask, this.events);
            window.setTimeout(() => {
              this.loading = false;
              this.$nextTick(() => {
                console.log('Loading finished');
              });
            }, 2000);
            // })
          } else {
            console.log('upload error');
          }
        }).catch((err) => {
          console.log(err);
        });
      } catch (error) {
        console.error('Error during image compression:', error);
      } finally {
        this.loading = false;
      }
    },
    action(event) {
      const newTime = new Date();
      console.log(event.event, '|||||');
      this.dialog = true;
      if (this.newTask.start < newTime.getTime()) {
        this.btnColor = 'red';
      } else {
        this.btnColor = '#4DBCB8';
      }
      this.newTask = event.event;
    },
    doubleCheck() {
      this.doubleDialog = true;
      navigator
        .geolocation
        .getCurrentPosition(this.successCallback, this.errorCallback, {
          enableHighAccuracy: true,
          maximumAge: 10000,
        });
    },
    async report(event) {
      this.doubleDialog = false;
      this.dialog = false;
      console.log('fire report', this.comments, event);
      const newTime = new Date();
      await console.log('????', this.newTask.comments);
      await api.report({
        reportTime: newTime.getTime(),
        id: this.newTask.id,
        lat: this.location.lat,
        lon: this.location.lon,
        memberId: this.$store.state.userInfo.id,
        editor: this.$store.state.userInfo.name,
        status: event,
        task: this.newTask,
        comments: this.newTask.comments ? this.newTask.comments : this.comments,
      })
        .then(async ({ data }) => {
          if (data.success) {
            console.log('report response', data.info);
            this.loadingGeo = true;
          } else {
            console.log('err:', data);
          }
        });
    },
    getMobileTask() {
      const currentTime = new Date();
      const calYear = currentTime.getFullYear();
      const calMonth = (currentTime.getMonth() + 1).toString().padStart(2, '0');
      const calDay = (currentTime.getDate()).toString().padStart(2, '0');
      const todayTime = new Date(`${calYear}-${calMonth}-${calDay}`);
      // const todayTime = new Date('2023-03-21');
      // console.log((new Date(this.focus)).getTime(), 'LLLLLL');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('start', '>=', todayTime.getTime() - 28800000)
        .where('start', '<', todayTime.getTime() + 57600000)
        // .where('start', '>=', todayTime.getTime())
        // .where('start', '<', todayTime.getTime() + 86400000)
        .where('actived', '==', true)
        .where('memberList', 'array-contains', this.$store.state.userInfo.name)
        .onSnapshot(async (snapshot) => {
          this.events = await [];
          console.log('fire renew');
          await snapshot.forEach(async (change) => {
            const newchange = await change.data();
            newchange.category = await this.$store.state.userInfo.name;
            this.events.push(newchange);
            if (change.data().id === this.newTask.id) {
              // window.setTimeout(() => {
              //   const found = this.events.find((ele) => ele.id === this.newTask.id);
              //   this.$set(this, 'newTask', found);
              // }, 3000);
              window.setTimeout(() => {
                this.$set(this, 'newTask', change.data());
              }, 2000);
              // this.$set(this, 'newTask', change.data());
            }
            // this.newTask = change.data();
            console.log(this.events, 'KLKLKKKKKKKKKK', this.categories);
          });
        });
    },
  },
};
</script>
<style scoped>
.reportButton {
  font-size:30px;
  width:100%;
  font-color:white;
  padding: 10px auto;
}
</style>
