<template>
  <div class="dashboard">
    <p style="margin-top:60px;font-size:32px;">
      {{ today }}
    </p>
    <v-row v-if="loading">
      <v-col xs="12" sm="12" style="text-align: center;">
        <v-progress-circular
          style="margin:auto"
          :size="90"
          :width="9"
          color="#4DBCB8"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col xs="12" sm="4" v-for="(area,index) in areas" :key="area.name">
        <v-card
          style="min-height:216px;overflow:hidden;"
          @mouseover="showDelete(index)"
        >
          <v-card-title>
            <v-row>
              <v-col xs="10" sm="10">
                {{ area.name }}
              </v-col>
              <v-col xs="2" sm="2" style="text-align:center;">
                <v-icon
                  v-show="currentIndex === index"
                  color="red"
                  style="margin:auto"
                  @click="checkDelete(index)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            style="cursor:pointer;"
            @click="goDetail(area.name)">
            <v-row v-if="$vuetify.breakpoint.smAndUp">
              <v-col cols="7" style="font-size:18px;">
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-account</v-icon>
                  <span style="margin-left:20px;">今日人員</span>
                  <span style="margin-left:20px;">
                    {{area.todayWorkers.length}}
                  </span>
                </p>
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-clipboard-outline</v-icon>
                  <span style="margin-left:20px;">今日任務</span>
                  <span style="margin-left:20px;">
                    {{area.totalTasks}}
                  </span>
                </p>
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-clipboard-check-outline</v-icon>
                  <span style="margin-left:20px;">完成任務</span>
                  <span style="margin-left:20px;">
                    {{area.finishedTasks}}
                  </span>
                </p>
              </v-col>
              <v-col cols="5">
                <v-progress-circular
                  v-if="area.totalTasks === 0"
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="100"
                  color="grey"
                >
                  no task
                </v-progress-circular>
                <v-progress-circular
                  v-else
                  :rotate="-90"
                  :size="100"
                  :width="15"
                  :value="(area.finishedTasks/area.totalTasks)*100"
                  color="#4DBCB8"
                >
                  {{ (area.finishedTasks/area.totalTasks*100).toFixed(0) }} %
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="7" style="font-size:18px;">
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-account</v-icon>
                  <span style="margin-left:10px;">今日人員</span>
                  <span style="margin-left:10px;">
                    {{area.todayWorkers.length}}
                  </span>
                </p>
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-clipboard-outline</v-icon>
                  <span style="margin-left:10px;">今日任務</span>
                  <span style="margin-left:10px;">
                    {{area.totalTasks}}
                  </span>
                </p>
                <p>
                  <v-icon large style="color:#4DBCB8">mdi-clipboard-check-outline</v-icon>
                  <span style="margin-left:10px;">完成任務</span>
                  <span style="margin-left:10px;">
                    {{area.finishedTasks}}
                  </span>
                </p>
              </v-col>
              <v-col cols="5" style="margin-top:20px;">
                <v-progress-circular
                  v-if="area.totalTasks === 0"
                  :rotate="360"
                  :size="100"
                  :width="10"
                  :value="100"
                  color="grey"
                >
                  no task
                </v-progress-circular>
                <v-progress-circular
                  v-else
                  :rotate="-90"
                  :size="100"
                  :width="10"
                  :value="(area.finishedTasks/area.totalTasks)*100"
                  color="#4DBCB8"
                >
                  {{ (area.finishedTasks/area.totalTasks*100).toFixed(0) }} %
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="4" v-if="this.$store.state.userInfo.position === 'admin'">
        <v-card
          @click="dialog = true"
          style="height:216px;text-align:center;cursor:pointer;"
        >
          <v-icon
            color="#4DBCB8"
            size="80"
            style="margin-top:70px"
          >
            mdi-plus-circle-outline
          </v-icon>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="40%">
      <v-card style="overflow:hidden">
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="newArea"
              style="padding:26px 10px 0px 10px;"
              label="輸入新區域名稱"
              placeholder="ex: 外島"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              @click="addNewArea"
              dark
              height="70"
              width="70"
              style="margin:7px 22px 0px 0px"
              color="#4DBCB8">
              新增
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="260">
      <v-card style="overflow:hidden">
        <v-card-title style="color:#BC5156">
          <span v-if="areas[currentIndex] !== undefined">
            確認刪除「{{areas[currentIndex]['name']}}」 ？
          </span>
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                style="width:100%"
                color="#BC5156"
                outlined
                @click="removeArea"
              >
                刪除
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                style="width:100%"
                color="grey"
                outlined
                @click="deleteDialog = false"
              >
                取消
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../api/detail';
import { db } from '../db';

export default {
  name: 'Dashboard',
  data: () => ({
    loading: true,
    deleteDialog: false,
    currentIndex: null,
    dialog: false,
    newArea: '',
    areas: [],
  }),
  computed: {
    today() {
      const time = new Date();
      const year = time.getFullYear();
      const month = (time.getMonth() + 1);
      const day = time.getDate();
      const weekDay = time.getDay();
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

      return `${year}年${month}月${day}日 ${days[weekDay]}`;
    },
  },
  mounted() {
    api.getAreas()
      .then(async ({ data }) => {
        if (data.success) {
          this.loading = false;
          data.areas.forEach((ele) => {
            this.areas.push({
              name: ele.name,
              todayWorkers: [],
              totalTasks: 0,
              finishedTasks: 0,
            });
          });
        } else {
          console.log('get areas wrong');
        }
      })
      .then(() => {
        this.getToday();
      });
    // await api.getDashboard()
    //   .then(async ({ data }) => {
    //     if (data.success) {
    //       if (data.taskAmount !== null) {
    //         data.taskAmount.forEach((ele) => {
    //           const found = this.areas.find((el) => el.name === ele.region);
    //           found.totalTasks = ele.amount;
    //           found.finishedTasks = ele.finished;
    //         });
    //         data.workers.forEach((ele) => {
    //           const found = this.areas.find((el) => el.name === ele.region);
    //           found.todayWorkers = ele.availableWorkers.length;
    //         });
    //       }
    //       console.log(data, 'workerssssssss');
    //     } else {
    //       console.log('err:', data);
    //     }
    //   });
  },
  methods: {
    async getArea() {
      await this.$set(this, 'areas', []);
    },
    getToday() {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth();
      const day = time.getDate();
      const newTime = new Date(year, month, day);
      console.log(newTime, '@@@@@@');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('start', '>=', newTime.getTime())
        .where('start', '<', newTime.getTime() + 86400000)
        .where('actived', '==', true)
        .onSnapshot(async (snapshot) => {
          await this.areas.forEach((area) => {
            this.$set(area, 'todayWorkers', []);
            this.$set(area, 'totalTasks', 0);
            this.$set(area, 'finishedTasks', 0);
            // area.todayWorkers = [];
            // area.totalTasks = 0;
            // area.finishedTasks = 0;
          });
          await snapshot.forEach(async (change) => {
            const found = await this.areas.find((ele) => ele.name === change.data().region);
            change.data().member.forEach((e) => {
              console.log(found, 'KKKK', this.areas);
              if (!found.todayWorkers.some((el) => el === e.name)) {
                found.todayWorkers.push(e.name);
              }
            });
            found.totalTasks += 1;
            if (change.data().finished) {
              found.finishedTasks += 1;
            }
            console.log('TTTTTTT', change.data().member.length, this.areas);
          });
        });
    },
    async updateAreas() {
      const newAreas = await [];
      await this.areas.forEach((ele) => {
        newAreas.push({
          name: ele.name,
          categories: ele.categories,
        });
      });
      await api.updateAreas({ areas: newAreas })
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data, 'update areas success');
          } else {
            console.log('err:', data);
          }
        });
    },
    async removeArea() {
      await this.areas.splice(this.currentIndex, 1);
      await this.updateAreas();
      this.deleteDialog = false;
    },
    checkDelete(index) {
      this.currentIndex = index;
      this.deleteDialog = true;
    },
    showDelete(index) {
      if (this.$store.state.userInfo.position === 'director') {
        this.currentIndex = index;
      }
    },
    async addNewArea() {
      if (this.newArea.length > 0) {
        await this.areas.push({
          name: this.newArea,
          todayWorkers: [],
          totalTasks: 0,
          finishedTasks: 0,
        });
        await this.updateAreas();
        this.dialog = false;
      } else {
        alert('請輸入要新增區域名稱');
      }
    },
    async goDetail(area) {
      const time = new Date();
      const year = time.getFullYear();
      const month = (time.getMonth() + 1).toString().padStart(2, '0');
      const day = (time.getDate()).toString().padStart(2, '0');
      // this.$store.state.region = area;
      await this.$set(this.$store.state, 'region', area);
      await this.$router.push(`/detail/${year}${month}${day}`);
    },
  },
};
</script>
<style scoped lang="scss">
.col {
  flex-basis: auto;
  flex-grow: 1;
}
</style>
